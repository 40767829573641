<template>
  <div>
    <div class="dropdown-list">
      <drop-down
          :title="'Ресторан'"
          :options="optionsRestaurants"
          :selected="selectedRestaurant"
          @select-deal="optionSelectRestaurant"
      />

      <drop-down-calendar
          @select-deal="optionSelectDate"
      />
      <slot v-if="filter.restaurant === 'Ribambelle Obolensky'">
        <drop-down
            :title="'Направление'"
            :options="optionsDirectionsOB"
            :selected="selectedDirection"
            @select-deal="optionSelectDirection"
        /></slot>
      <slot v-else>

        <drop-down
            :title="'Направление'"
            :options="optionsDirections"
            :selected="selectedDirection"
            @select-deal="optionSelectDirection"
        />
      </slot>
      <slot v-if="canRequest"><button @click="loadData">Применить фильтр</button></slot><slot v-else><button class="disabled" disabled readonly="">Идет запрос, он может занимать до 5 минут. Подождите немного...</button></slot>

    </div>
    <h1 class="container" v-if="error">{{error}}</h1>
    <slot v-else-if="canRequest">
    <div class="indicator-list">
      <div class="indicator-element" v-if="table.sum">
        <div class="main-info-check">
          <div class="main-info-check__title">Выручка</div>
          <div class="main-info-check__cost">{{priceFormat(table.sum)}}</div>
          <div class="main-info-check__stats"><span :style="(table.sum - table.old.sum) > 0 ? {}:{color: '#AB5151'}">{{ (table.sum - table.old.sum) > 0 ? '+ '+priceFormat(table.sum - table.old.sum):priceFormat(table.sum - table.old.sum) }}</span> к прошлому году</div>
        </div>
        <div class="deep-check" style="display: none">
          <div class="deep-check__title">Глубина чека</div>
          <div class="deep-check__stats"><span>17</span> позиций в чеке</div>
        </div>
      </div>
      <div class="indicator-element" v-if="table.guest && filter.direction !== 'Банкеты Доп.счет' && filter.direction !== 'Творчество' && filter.direction !== 'Кейтеринг' && filter.direction !== 'Доставка'">
        <div class="main-info-check">
          <div class="main-info-check__title">Гостей</div>
          <div class="main-info-check__cost">{{ numberFormat(table.guest) }} чел.</div>
          <div class="main-info-check__stats"><span :style="(table.guest - table.old.guest) > 0 ? {}:{color: '#AB5151'}">{{ (table.guest - table.old.guest) > 0 ? '+ '+numberFormat(table.guest - table.old.guest):numberFormat(table.guest - table.old.guest) }} чел.</span> к прошлому году</div>
        </div>
        <div class="deep-check" style="display: none">
          <div class="deep-check__title">Глубина чека</div>
          <div class="deep-check__stats"><span>17</span> позиций в чеке</div>
        </div>
      </div>
      <slot v-if="table.avgCheck && (filter.direction == 'Посадка')">
        <div class="indicator-element" v-if="filter.direction == null">
          <div class="main-info-check">
            <div class="main-info-check__title">Средний чек</div>
            <div class="main-info-check__cost">{{ priceFormat(table['Направления']['Посадка']/table.guest) }}</div>
            <div class="main-info-check__stats"><span :style="((table['Направления']['Посадка']/table.guest) - table.old.avgCheck) > 0 ? {}:{color: '#AB5151'}">{{ ((table['Направления']['Посадка']/table.guest) - table.old.avgCheck) > 0 ? '+ '+priceFormat((table['Направления']['Посадка']/table.guest) - table.old.avgCheck):priceFormat((table['Направления']['Посадка']/table.guest) - table.old.avgCheck) }}</span> к прошлому году</div>
          </div>
          <div class="deep-check" v-if="table.avgCount">
            <div class="deep-check__title">Глубина чека</div>
            <div class="deep-check__stats"><span>{{ roundN(table.avgCount) }}</span> позиций в чеке</div>
          </div>
        </div>
        <div class="indicator-element" v-else-if="filter.direction == 'Посадка'">
          <div class="main-info-check">
            <div class="main-info-check__title">Средний чек</div>
            <div class="main-info-check__cost">{{ priceFormat(table.avgCheck) }}</div>
            <div class="main-info-check__stats"><span :style="(table.avgCheck - table.old.avgCheck) > 0 ? {}:{color: '#AB5151'}">{{ (table.avgCheck - table.old.avgCheck) > 0 ? '+ '+priceFormat(table.avgCheck - table.old.avgCheck):priceFormat(table.avgCheck - table.old.avgCheck) }}</span> к прошлому году</div>
          </div>
          <div class="deep-check" v-if="table.avgCount">
            <div class="deep-check__title">Глубина чека</div>
            <div class="deep-check__stats"><span>{{ roundN(table.avgCount) }}</span> позиций в чеке</div>
          </div>
        </div>
      </slot>
      <div class="chart-info" v-if="PieChartDataAll.length > 1 && filter.direction === null && table && table['Направления']">
        <div class="chart-info__title">Доля выручки</div>
<!--        <PieChart-->
<!--          :width="sizePie.width"-->
<!--          :height="sizePie.height"-->
<!--        />-->
       <google-chart :key="load + JSON.stringify(sizeLineChart)"
            :type="PieChartType"
            :data="PieChartDataAll"
            :options="PieChartOptions"
        />
<!--        <div class="chart-info__description"><span>+ 17% </span> к прошлому году</div>-->
      </div>
      <div class="chart-info" v-if="PieChartDataBanket.length > 1 && filter.direction === 'Банкеты Доп.счет' && table && table['Направления']">
        <google-chart
            :type="PieChartType"
            :data="PieChartDataBanket"
            :options="PieChartOptions"
        />
<!--        <div class="chart-info__description"><span>+ 17% </span> к прошлому году</div>-->
      </div>
      <div class="indicator-element" v-if="table.avgPositions && filter.direction === 'Посадка'">
        <div class="main-info-check">
          <div class="main-info-check__title">Средняя цена</div>
          <div class="main-info-check__cost">{{ priceFormat(table.avgPositions) }}</div>
          <div class="main-info-check__stats"><span :style="(table.avgPositions - table.old.avgPositions) > 0 ? {}:{color: '#AB5151'}">{{ (table.avgPositions - table.old.avgPositions) > 0 ? '+ '+priceFormat(table.avgPositions - table.old.avgPositions):priceFormat(table.avgPositions - table.old.avgPositions) }}</span> к прошлому году</div>
        </div>
      </div>
      <div class="indicator-element" v-if="table.bankets && filter.direction === 'Банкеты предзаказ'">
        <div class="main-info-check">
          <div class="main-info-check__title">Банкетов</div>
          <div class="main-info-check__cost">{{ numberFormat(table.bankets) }}</div>
          <div class="main-info-check__stats"><span :style="(table.bankets - table.old.bankets) > 0 ? {}:{color: '#AB5151'}">{{ (table.bankets - table.old.bankets) > 0 ? '+ '+numberFormat(table.bankets - table.old.bankets):numberFormat(table.bankets - table.old.bankets) }}</span> к прошлому году</div>
        </div>
      </div>
      <div class="indicator-element" v-if="table.bankets && filter.direction === 'Кейтеринг'">
        <div class="main-info-check">
          <div class="main-info-check__title">Заказов</div>
          <div class="main-info-check__cost">{{ numberFormat(table.bankets) }}</div>
          <div class="main-info-check__stats"><span :style="(table.bankets - table.old.bankets) > 0 ? {}:{color: '#AB5151'}">{{ (table.bankets - table.old.bankets) > 0 ? '+ '+numberFormat(table.bankets - table.old.bankets):numberFormat(table.bankets - table.old.bankets) }}</span> к прошлому году</div>
        </div>
      </div>
      <div class="indicator-element" v-if="table.bankets && filter.direction === 'Кейтеринг'">
        <div class="main-info-check">
          <div class="main-info-check__title">Средний чек</div>
          <div class="main-info-check__cost">{{ priceFormat(table.sum/table.bankets) }}</div>
          <div class="main-info-check__stats"><span :style="((table.sum/table.bankets) - ((table.old.sum/table.old.bankets)||0)) > 0 ? {}:{color: '#AB5151'}">{{ ((table.sum/table.bankets) - ((table.old.sum/table.old.bankets)||0)) > 0 ? '+ '+priceFormat((table.sum/table.bankets) - ((table.old.sum/table.old.bankets)||0)):priceFormat((table.sum/table.bankets) - ((table.old.sum/table.old.bankets)||0)) }}</span> к прошлому году</div>
        </div>
      </div>
      <div class="indicator-element" v-if="table.bankets && filter.direction === 'Творчество'">
        <div class="main-info-check">
          <div class="main-info-check__title">Мастер-классов</div>
          <div class="main-info-check__cost">{{ numberFormat(table.bankets) }}</div>
          <div class="main-info-check__stats"><span :style="(table.bankets - table.old.bankets) > 0 ? {}:{color: '#AB5151'}">{{ (table.bankets - table.old.bankets) > 0 ? '+ '+numberFormat(table.bankets - table.old.bankets):numberFormat(table.bankets - table.old.bankets) }}</span> к прошлому году</div>
        </div>
      </div>
      <div class="indicator-element" v-if="table.avgCheck && filter.direction === 'Творчество'">
        <div class="main-info-check">
          <div class="main-info-check__title">Конверсия</div>
          <div class="main-info-check__cost">{{numberFormat(table.bankets/table.guest*100)}}%</div>
          <div class="main-info-check__stats"><span :style="(((table.bankets/table.guest*100)) - ((table.old.bankets/table.old.guest*100))) > 0 ? {}:{color: '#AB5151'}">{{ (((table.bankets/table.guest*100)) - ((table.old.bankets/table.old.guest*100))) > 0 ? '+ '+numberFormat(((table.bankets/table.guest*100)) - ((table.old.bankets/table.old.guest*100))):numberFormat(((table.bankets/table.guest*100)) - ((table.old.bankets/table.old.guest*100))) }}</span> к прошлому году</div>
        </div>
      </div>
      <div class="chart-info" v-if="filter.direction === 'Доставка'">
        <google-chart :key="load + JSON.stringify(sizeLineChart)"
                      :type="PieChartType"
                      :data="DeliveryChartData"
                      :options="PieChartOptions"
        />
        <!--        <div class="chart-info__description"><span>+ 17% </span> к прошлому году</div>-->
      </div>
    </div>
    <div :class="filter.direction!=='Банкеты Доп.счет' && filter.direction!=='Доставка'?'colspan_4':('colspan_2')">
    <div class="chart-line-container" style="display: inline-block" v-if="chartAll.init">
      <canvas id="LineChartDataAll"
              :width="sizeLineChart.width"
              :height="sizeLineChart.height"
      ></canvas>
<!--      <bar-chart :key="load + JSON.stringify(sizeLineChart)"
          :data="chartAll"
          :width="sizeLineChart.width"
          :height="sizeLineChart.height"
      />-->
    </div>
    <div class="chart-line-container" style="display: inline-block" v-if="chartGuest.init && filter.direction !== 'Банкеты Доп.счет' && filter.direction !== 'Творчество' && filter.direction !== 'Кейтеринг' && filter.direction !== 'Доставка'">
      <canvas id="LineChartDataGuest"
              :width="sizeLineChart.width"
              :height="sizeLineChart.height"
      ></canvas>
      <!--            <bar-chart
                :key="load + JSON.stringify(sizeLineChart)"
                :data="chartGuest"
                :width="sizeLineChart.width"
                :height="sizeLineChart.height"
            />-->
    </div>
    <div class="chart-line-container" style="display: inline-block" v-if="(filter.direction == 'Кейтеринг')"><!-- || filter.direction == null-->
      <!--      <google-chart-->
      <!--          :type="ColumnChartType"-->
      <!--          :data="ColumnChartDataAvgCheck"-->
      <!--          :options="ColumnChartOptions"-->
      <!--      />-->
      <canvas id="ColumnChartDataAvgCheck"
              :width="sizeLineChart.width"
              :height="sizeLineChart.height"
      ></canvas>
    </div>
    <div class="chart-line-container" style="display: inline-block" v-if="(filter.direction == 'Посадка')"><!-- || filter.direction == null-->
<!--      <google-chart-->
<!--          :type="ColumnChartType"-->
<!--          :data="ColumnChartDataAvgCheck"-->
<!--          :options="ColumnChartOptions"-->
<!--      />-->
      <canvas id="ColumnChartDataAvgCheck"
              :width="sizeLineChart.width"
              :height="sizeLineChart.height"
      ></canvas>
    </div>
    <div class="parameters-list" style="display: inline-block" v-if="filter.direction === null && table && table['Направления']">
      <div class="parameters-element" v-for="(val, key) in table['Направления']" v-if="key !== 'Городок' && filter.restaurant === 'Ribambelle Obolensky' || filter.restaurant !== 'Ribambelle Obolensky'">
        <div class="parameters-element__title">{{ key }}</div>
        <div class="parameters-element__value">{{ priceFormat(val) }}</div>
      </div>
    </div>
    <div class="chart-line-container" style="display: inline-block" v-if="filter.direction === 'Посадка'">
<!--      <google-chart-->
<!--          :type="ColumnChartType"-->
<!--          :data="ColumnChartDataAvgPrice"-->
<!--          :options="ColumnChartOptions"-->
<!--      />-->
      <canvas id="ColumnChartDataAvgPrice"
              :width="sizeLineChart.width"
              :height="sizeLineChart.height"></canvas>
    </div>
    <div class="chart-line-container" style="display: inline-block" v-if="filter.direction === 'Банкеты предзаказ'">
<!--      <google-chart-->
<!--          :type="ColumnChartType"-->
<!--          :data="ColumnChartDataBankets"-->
<!--          :options="ColumnChartOptions"-->
<!--      />-->
      <canvas id="ColumnChartDataBankets"
              :width="sizeLineChart.width"
              :height="sizeLineChart.height"></canvas>
    </div>
    <div class="chart-line-container" style="display: inline-block" v-if="filter.direction === 'Кейтеринг'">
<!--      <google-chart-->
<!--          :type="ColumnChartType"-->
<!--          :data="ColumnChartDataBankets"-->
<!--          :options="ColumnChartOptions"-->
<!--      />-->
      <canvas id="ColumnChartDataBankets"
              :width="sizeLineChart.width"
              :height="sizeLineChart.height"></canvas>
    </div>
    <div class="chart-line-container" style="display: inline-block" v-if="chartBankets.init && filter.direction === 'Творчество'">
<!--      <google-chart
          :type="LineChartType"
          :data="LineChartData"
          :options="LineChartOptions"
      />-->
      <canvas id="LineChartDataBankets"
              :width="sizeLineChart.width"
              :height="sizeLineChart.height"
      ></canvas>
<!--      <bar-chart
          :key="load + JSON.stringify(sizeLineChart)"
          :data="chartBankets"
          :width="sizeLineChart.width"
          :height="sizeLineChart.height"
      />-->
    </div>
    </div>
    </slot>
  </div>
</template>

<script>
import DropDown from "@/Components/dropDown";
import DropDownCalendar from "@/Components/dropDownCalendar";
import GoogleChart from "@/Components/GoogleChart";
import {FunctionalCalendar} from "vue-functional-calendar";
import axios from "axios";
import BarChart from "@/Components/BarChart";
import PieChart from "@/Components/PieChart";
import Chart from 'chart.js/auto'

export default {
  name: "SecondHomeView",
  components: {PieChart, GoogleChart, BarChart, DropDown, DropDownCalendar},
  data(){
    return{
      error: null,
      sizeLineChart:{
        height:0,
        width:0,
      },
      sizePie:{
        height:0,
        width:0,
      },
      optionsRestaurants: [
        {name:'ВГ | Лок. A', value:'RIBAMBELLE Vremena Goda'},
        {name:'Бс | Лок. B', value:'RIBAMBELLE Green'},
        {name:'ОБ | Лок. C', value:'Ribambelle Obolensky'},
        {name:'СС | Лок. D', value:'Ribambelle Сердце Столицы'}
      ],

      optionsDirectionsOB: [
        {name: 'Не выбрано', value: null},
        {name:'Посадка', value:'Посадка'},
        {name:'Банкеты предзаказ', value:'Банкеты предзаказ'},
        {name:'Банкеты Доп.счет', value:'Банкеты Доп.счет'},
        {name:'Кейтеринг', value:'Кейтеринг'},
        {name:'Творчество', value:'Творчество'},
        // {name:'Магазин', value:'Магазин'},
        // {name:'Городок', value:'Городок'},
        {name:'Доставка', value:'Доставка'},
      ],
      optionsDirections: [
        {name: 'Не выбрано', value: null},
        {name:'Посадка', value:'Посадка'},
        {name:'Банкеты предзаказ', value:'Банкеты предзаказ'},
        {name:'Банкеты Доп.счет', value:'Банкеты Доп.счет'},
        {name:'Кейтеринг', value:'Кейтеринг'},
        {name:'Творчество', value:'Творчество'},
        // {name:'Магазин', value:'Магазин'},
        {name:'Городок', value:'Городок'},
        {name:'Доставка', value:'Доставка'},
      ],
      load: 0,
      chart: {
        labels: [],
        oldYear: [],
        thisYear: [],
        plan: []
      },
      chartAll: {
        labels: [],
        oldYear: [],
        thisYear: [],
        plan: [],
        init: false
      },
      chartGuest: {
        labels: [],
        oldYear: [],
        thisYear: [],
        plan: [],
        init: false
      },
      chartBankets: {
        labels: [],
        oldYear: [],
        thisYear: [],
        plan: [],
        init: false
      },
      timer: null,
      selectedRestaurant:null,
      selectedDate:null,
      selectedDirection:null,
      LineChartType:'LineChart',
      LineChartData:[
        ['Data', 'План', 'Факт', 'Прошлый'],
        ['2014', 1000, 400, 200],
        ['2015', 1170, 460, 250],
        ['2016', 660, 1120, 300],
        ['2017', 1030, 540, 350]
      ],
      LineChartOptions:{
        chart: {
          title: 'Company Performance',
          subtitle: 'Sales, Expenses, and Profit: 2014-2017',
        },
        chartArea:{left:'20px',width:'85%',height:'90%'},
        width: 400,
        height: 252,
        series: {
          0: {
            color: '#ff9100',
          },
          1: {
            color: '#868686',
          },
          2: {
            color: '#284F9A',
          },
        },
        legend: {
          position:'top',
          textStyle: {
            bold: true,
            color: '#222222',
            fontSize: 12,
            fontName:'Circe Bold',
          }
        },
      },
      PieChartType:'PieChart',
      PieChartDataAll:[
        ['Name', 'Count'],
      ],
      PieChartDataBanket:[
        ['Name', 'Count'],
      ],
      DeliveryChartData: [
        ['Name', 'Count'],
      ],
      PieChartOptions:{
        title: '',
        width: 400,
        height: 221,
        chartArea:{top:0,width:'100%',height:'100%'},
        slices: {
          0: { color: '#AFCBBF' },
          1: { color: '#C5B2C3' },
          2: { color: '#EAE8D8' },
        },
        pieSliceTextStyle:{
          fontSize:12,
          color: '#222222',
          fontName:'Circe Bold',
        },
        legend: 'none',
      },
      PieChartOptionsCustom:{
        title: '',
        width: 400,
        height: 221,
        chartArea:{top:0,width:'100%',height:'100%'},
        slices: {
          0: { color: '#AFCBBF' },
          1: { color: '#C5B2C3' },
          2: { color: '#EAE8D8' },
        },
        pieSliceTextStyle:{
          fontSize:12,
          color: '#222222',
          fontName:'Circe Bold',
        },
        legend: 'none',
      },
      ColumnChartType:'ColumnChart',
      LineChartDataAll:[],
      LineChartDataGuest:[],
      LineChartDataBankets:[],
      ColumnChartDataAvgCheck:[],
      ColumnChartDataAvgPrice:[],
      ColumnChartDataBankets:[],
      ColumnChartOptions:{
        colors: ['#AFCBBF','#2E5475'],
        chartArea:{left:'20px',width:'80%',height:'90%'},
        width: 500,
        height: 400,
        legend: {
          position:'top',
          textStyle: {
            bold: true,
            color: '#222222',
            fontSize: 12,
            fontName:'Circe Bold',
          }
        },
        hAxis:{
          textStyle: {
            bold: true,
            color: '#222222',
            fontSize: 12,
            fontName:'Circe Bold',
          }
        },
        vAxis:{
          textStyle: {
            bold: true,
            color: '#222222',
            fontSize: 12,
            fontName:'Circe Bold',
          }
        }
      },
      filter: {
        restaurant: null,
        date: null,
        direction: null
      },
      table: {},
      chartData: {
        labels: [],
        datasets: []
      },
      chart1: null,
      chart2: null,
      chart3: null,
      chart4: null,
      chart5: null,
      chart6: null,
      chart7: null,
      canRequest: true
    }
  },
  methods:{
    roundN(val) {
      return Number(val).toFixed(2)
    },
    priceFormat(value) {
      if(!value) return '0 ₽'
      var formatter = new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
        minimumFractionDigits: 0
      })
      return formatter.format((value || 0)) || '0 ₽'
    },
    numberFormat(value) {
      if(!value) return ''
      var formatter = new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: 0
      })
      return formatter.format((value || 0)) || 0
    },
    optionSelectRestaurant(option){
      this.selectedRestaurant=option.name;
      this.filter.restaurant=option.value;
    },
    optionSelectDate(option){
      this.filter.date=option;
    },
    optionSelectDirection(option){
      if(option.value === 'null') option.value = null
      this.selectedDirection=option.name;
      this.filter.direction=option.value;
    },
    loadData() {
      this.canRequest = false
      var url = []
      if(this.filter.restaurant) url.push('restaurant='+this.filter.restaurant)
      if(this.filter.date&&this.filter.date.start) url.push('start='+this.filter.date.start)
      if(this.filter.date&&this.filter.date.end) url.push('end='+this.filter.date.end)
      if(this.filter.direction) url.push('direction='+this.filter.direction)

      this.sizeLineChart = {
        height:0,
        width:0,
      }

      axios.get('https://graph-api.ribambelle.ru/site/page2?'+url.join('&')).then(res=> {
        this.table = res.data
        this.PieChartDataAll = []
        this.PieChartDataAll.push(['Name', 'Count'])
        var directions = this.table['Направления']
        Object.keys(directions).forEach(key => {
          var value = directions[key]
          this.PieChartDataAll.push([key, value])
        })

        if(this.filter.direction === 'Доставка'){
        this.DeliveryChartData = []
        this.DeliveryChartData.push(['Name', 'Count'])
        var directions = this.table['Доставка']
        Object.keys(directions).forEach(key => {
          var value = directions[key]
          this.DeliveryChartData.push([key, value])
        })
        }

        this.PieChartDataBanket = []
        this.PieChartDataBanket.push(['Name', 'Count'])
        var bankets = this.table['Банкеты']

        this.PieChartDataBanket.push(['Всего', bankets.all-bankets.dop])
        this.PieChartDataBanket.push(['Доп счет', bankets.dop])

        this.chartAll={
          labels: [],
          oldYear: [],
          thisYear: [],
          plan: [],
          init: false
        }
        if(this.table.graphs.all) {
          Object.values(this.table.graphs.all).forEach(val => {
            this.chartAll.labels.push(val.date)
            this.chartAll.thisYear.push(val['this'])
            this.chartAll.oldYear.push(val['last'])
            this.chartAll.plan.push(val['plan'])
          })
          this.chartAll.init = true
        }

        this.chartGuest={
          labels: [],
          oldYear: [],
          thisYear: [],
          plan: [],
          init: false
        }

        if(this.table.graphs.guest) {
          Object.values(this.table.graphs.guest).forEach(val => {
            this.chartGuest.labels.push(val.date)
            this.chartGuest.thisYear.push(val['this'])
            this.chartGuest.oldYear.push(val['last'])
            this.chartGuest.plan.push(val['plan'])
          })
          this.chartGuest.init = true
        }

        this.chartBankets={
          labels: [],
          oldYear: [],
          thisYear: [],
          plan: [],
          init: false
        }

        if(this.table.graphs.bankets) {
          Object.values(this.table.graphs.bankets).forEach(val => {
            this.chartBankets.labels.push(val.date)
            this.chartBankets.thisYear.push(val['this'])
            this.chartBankets.oldYear.push(val['last'])
            this.chartBankets.plan.push(val['plan'])
          })
          this.chartBankets.init = true
        }
        Chart.defaults.font.size=12
        Chart.defaults.font.family='Circe Bold'
        Chart.defaults.color = "#222222";
        if(this.selectedDirection === 'Кейтеринг') {
          var labels2 = []
          this.ColumnChartDataAvgCheck = [
            {
              label: 'ТЕКУЩИЙ ГОД',
              data: [],
              backgroundColor: 'rgba(54, 162, 235, 1)',
              // borderColor: 'rgba(54, 162, 235, 1)',
              // borderWidth: 1,
              // order: 2
            },
            {
              label: 'ПРОШЛЫЙ ГОД',
              data: [],
              backgroundColor: 'rgba(255, 26, 104, 1)',
              // borderColor: 'rgba(255, 26, 104, 1)',
              // borderWidth: 1,
              // order: 1
            }
          ]
          Object.values(this.table.graphs.bankets2).forEach(val => {
            labels2.push(val.date)
            this.ColumnChartDataAvgCheck[0].data.push(val.this)
            this.ColumnChartDataAvgCheck[1].data.push(val.last)
          })
          setTimeout(()=> {
            var data = {
              labels: labels2,
              datasets: this.ColumnChartDataAvgCheck
            };
            if(this.chart1 !== null) this.chart1.destroy()
            var legendMargin= {
              id:'legendMargin',
              beforeInit(chart, args, options) {
                const fitValue = chart.legend.fit;
                chart.legend.fit= function fit(){
                  fitValue.bind(chart.legend)();
                  return this.height +=28
                }
              }
            }
            if(document.getElementById('ColumnChartDataAvgCheck') !== null) {
              console.log(data)
              this.chart1 = new Chart(
                  document.getElementById('ColumnChartDataAvgCheck'),
                  {
                    type: 'bar',
                    data,
                    options: {
                      scales: {
                        x: {
                          stacked: false
                        },
                        y: {
                          beginAtZero: true
                        }
                      }
                    },
                    plugins: [legendMargin]
                  });
            }
          }, 1000)
        }
        else if(this.table.graphs.avgCheck && this.selectedDirection !== 'Кейтеринг') {
          var labels = []
          this.ColumnChartDataAvgCheck = [
            {
              label: 'ТЕКУЩИЙ ГОД',
              data: [],
              backgroundColor: 'rgba(54, 162, 235, 1)',
              // borderColor: 'rgba(54, 162, 235, 1)',
              // borderWidth: 1,
              // order: 2
            },
            {
              label: 'ПРОШЛЫЙ ГОД',
              data: [],
              backgroundColor: 'rgba(255, 26, 104, 1)',
              // borderColor: 'rgba(255, 26, 104, 1)',
              // borderWidth: 1,
              // order: 1
            }
          ]
          Object.values(this.table.graphs.avgCheck).forEach(val => {
            labels.push(val.date)
            this.ColumnChartDataAvgCheck[0].data.push(Math.round(val.this))
            this.ColumnChartDataAvgCheck[1].data.push(Math.round(val.last))
          })
          setTimeout(()=> {
            var data = {
              labels: labels,
              datasets: this.ColumnChartDataAvgCheck
            };
            if(this.chart1 !== null) this.chart1.destroy()
            var legendMargin= {
              id:'legendMargin',
              beforeInit(chart, args, options) {
                const fitValue = chart.legend.fit;
                chart.legend.fit= function fit(){
                  fitValue.bind(chart.legend)();
                  return this.height +=28
                }
              }
            }
            if(document.getElementById('ColumnChartDataAvgCheck') !== null) {
              this.chart1 = new Chart(
                  document.getElementById('ColumnChartDataAvgCheck'),
                  {
                    type: 'bar',
                    data,
                    options: {
                      scales: {
                        x: {
                          stacked: false
                        },
                        y: {
                          beginAtZero: true
                        }
                      }
                    },
                    plugins: [legendMargin]
                  });
            }
          }, 1000)
        }
        if(this.table.graphs.avgPrice) {
          var labels3 = []
        this.ColumnChartDataAvgPrice = [
          {
            label: 'ТЕКУЩИЙ ГОД',
            data: [],
            backgroundColor: 'rgba(54, 162, 235, 1)',
            // borderColor: 'rgba(54, 162, 235, 1)',
            // borderWidth: 1,
            // order: 2
          },
          {
            label: 'ПРОШЛЫЙ ГОД',
            data: [],
            backgroundColor: 'rgba(255, 26, 104, 1)',
            // borderColor: 'rgba(255, 26, 104, 1)',
            // borderWidth: 1,
            // order: 1
          }
        ]
          Object.values(this.table.graphs.avgPrice).forEach(val => {
            labels3.push(val.date)
            this.ColumnChartDataAvgPrice[0].data.push(Math.round(val.this))
            this.ColumnChartDataAvgPrice[1].data.push(Math.round(val.last))
          })
        setTimeout(()=> {
          var data = {
            labels: labels3,
            datasets: this.ColumnChartDataAvgPrice
          };
          if(this.chart2 !== null) this.chart2.destroy()
          var legendMargin= {
            id:'legendMargin',
            beforeInit(chart, args, options) {
              const fitValue = chart.legend.fit;
              chart.legend.fit= function fit(){
                fitValue.bind(chart.legend)();
                return this.height +=28
              }
            }
          }
          if(document.getElementById('ColumnChartDataAvgPrice') !== null) {
            this.chart2 = new Chart(
                document.getElementById('ColumnChartDataAvgPrice'),
                {
                  type: 'bar',
                  data,
                  options: {
                    scales: {
                      x: {
                        stacked: false
                      },
                      y: {
                        beginAtZero: true
                      }
                    }
                  },
                  plugins: [legendMargin]
                });
          }
        }, 1000)
        }
        if(this.table.graphs.bankets && this.selectedDirection !== 'Кейтеринг') {
          var labels4 = []
        this.ColumnChartDataBankets = [
          {
            label: 'ТЕКУЩИЙ ГОД',
            data: [],
            backgroundColor: 'rgba(54, 162, 235, 1)',
            // borderColor: 'rgba(54, 162, 235, 1)',
            // borderWidth: 1,
            // order: 2
          },
          {
            label: 'ПРОШЛЫЙ ГОД',
            data: [],
            backgroundColor: 'rgba(255, 26, 104, 1)',
            // borderColor: 'rgba(255, 26, 104, 1)',
            // borderWidth: 1,
            // order: 1
          }
        ]
          Object.values(this.table.graphs.bankets).forEach(val => {
            labels4.push(val.date)
            this.ColumnChartDataBankets[0].data.push(Math.round(val.this))
            this.ColumnChartDataBankets[1].data.push(Math.round(val.last))
          })
        setTimeout(()=> {
          var data = {
            labels: labels4,
            datasets: this.ColumnChartDataBankets
          };
          if(this.chart3 !== null) this.chart3.destroy()
          var legendMargin= {
            id:'legendMargin',
            beforeInit(chart, args, options) {
              const fitValue = chart.legend.fit;
              chart.legend.fit= function fit(){
                fitValue.bind(chart.legend)();
                return this.height +=28
              }
            }
          }
          if(document.getElementById('ColumnChartDataBankets') !== null) {
            this.chart3 = new Chart(
                document.getElementById('ColumnChartDataBankets'),
                {
                  type: 'bar',
                  data,
                  options: {
                    scales: {
                      x: {
                        stacked: false
                      },
                      y: {
                        beginAtZero: true
                      }
                    }
                  },
                  plugins: [legendMargin]
                });
          }
        }, 1000)

        }
        else if(this.selectedDirection === 'Кейтеринг') {
          var dates = {}
          if(this.table.graphs.bankets2) {
          Object.values(this.table.graphs.bankets2).forEach((val, key) => {
            if(dates[key]===undefined) {
              dates[key]={
                bankets: val,
                sum: {}
              }
            } else dates[key].bankets = val
          })
        }
        if(this.table.graphs.all) {
          Object.values(this.table.graphs.all).forEach((val, key) => {
            if(dates[key]===undefined) {
              dates[key]={
                bankets: {},
                sum: val
              }
            } else dates[key].sum = val
          })
        }
        if(dates) {
          console.log(dates)
          var labels5 = []
          this.ColumnChartDataBankets = [
            {
              label: 'ТЕКУЩИЙ ГОД',
              data: [],
              backgroundColor: 'rgba(54, 162, 235, 1)',
              // borderColor: 'rgba(54, 162, 235, 1)',
              // borderWidth: 1,
              // order: 2
            },
            {
              label: 'ПРОШЛЫЙ ГОД',
              data: [],
              backgroundColor: 'rgba(255, 26, 104, 1)',
              // borderColor: 'rgba(255, 26, 104, 1)',
              // borderWidth: 1,
              // order: 1
            }
          ]
          Object.values(dates).forEach(val => {
            if(val.bankets && val.sum && val.bankets.this && val.sum.this) {
              labels5.push(val.bankets.date)
              this.ColumnChartDataBankets[0].data.push(Math.round(val.sum.this/val.bankets.this))
              this.ColumnChartDataBankets[1].data.push(Math.round(val.sum.last/val.bankets.last))
            }
          })
          setTimeout(()=> {
            var data = {
              labels: labels5,
              datasets: this.ColumnChartDataBankets
            };
            if(this.chart3 !== null) this.chart3.destroy()
            var legendMargin= {
              id:'legendMargin',
              beforeInit(chart, args, options) {
                const fitValue = chart.legend.fit;
                chart.legend.fit= function fit(){
                  fitValue.bind(chart.legend)();
                  return this.height +=28
                }
              }
            }
            if(document.getElementById('ColumnChartDataBankets') !== null) {
              this.chart3 = new Chart(
                  document.getElementById('ColumnChartDataBankets'),
                  {
                    type: 'bar',
                    data,
                    options: {
                      scales: {
                        x: {
                          stacked: false
                        },
                        y: {
                          beginAtZero: true
                        }
                      }
                    },
                    plugins: [legendMargin]
                  });
            }
          }, 1000)
        }
        }
        if(true) {
          if (this.selectedDirection === null) {
            this.LineChartDataAll = [
              {
                label: 'ПЛАН',
                data: this.chartAll.plan,
                backgroundColor: '#ff9100',
                borderColor: '#ff9100',
                borderWidth: 1,
                order: 2
              },
              {
                label: 'ФАКТ',
                data: this.chartAll.thisYear,
                backgroundColor: '#868686',
                borderColor: '#868686',
                borderWidth: 1,
                order: 2
              },
              {
                label: 'ПРОШЛЫЙ',
                data: this.chartAll.oldYear,
                backgroundColor: '#284F9A',
                borderColor: '#284F9A',
                borderWidth: 1,
                order: 2
              }
            ]
          } else {

            this.LineChartDataAll = [
              {
                label: 'ФАКТ',
                data: this.chartAll.thisYear,
                backgroundColor: '#868686',
                borderColor: '#868686',
                borderWidth: 1,
                order: 2
              },
              {
                label: 'ПРОШЛЫЙ',
                data: this.chartAll.oldYear,
                backgroundColor: '#284F9A',
                borderColor: '#284F9A',
                borderWidth: 1,
                order: 2
              }
            ]
          }
          setTimeout(()=> {
            var data = {
              labels: this.chartAll.labels,
              datasets: this.LineChartDataAll
            };
            if(this.chart5 !== null) this.chart5.destroy()
            var legendMargin= {
              id:'legendMargin',
              beforeInit(chart, args, options) {
                const fitValue = chart.legend.fit;
                chart.legend.fit= function fit(){
                  fitValue.bind(chart.legend)();
                  return this.height +=28
                }
              }
            }
            if(document.getElementById('LineChartDataAll') !== null) {
              this.chart5 = new Chart(
                  document.getElementById('LineChartDataAll'),
                  {
                    type: 'line',
                    data,
                    plugins: [legendMargin]
                  });
            }
          }, 1000)
        }
        if(true) {
          if (this.selectedDirection === null) {

            this.LineChartDataGuest = [
              {
                label: 'ПЛАН',
                data: this.chartGuest.plan,
                backgroundColor: '#ff9100',
                borderColor: '#ff9100',
                borderWidth: 1,
                order: 2
              },
              {
                label: 'ФАКТ',
                data: this.chartGuest.thisYear,
                backgroundColor: '#868686',
                borderColor: '#868686',
                borderWidth: 1,
                order: 2
              },
              {
                label: 'ПРОШЛЫЙ',
                data: this.chartGuest.oldYear,
                backgroundColor: '#284F9A',
                borderColor: '#284F9A',
                borderWidth: 1,
                order: 2
              }
            ]
          } else {


            this.LineChartDataGuest = [
              {
                label: 'ФАКТ',
                data: this.chartGuest.thisYear,
                backgroundColor: '#868686',
                borderColor: '#868686',
                borderWidth: 1,
                order: 2
              },
              {
                label: 'ПРОШЛЫЙ',
                data: this.chartGuest.oldYear,
                backgroundColor: '#284F9A',
                borderColor: '#284F9A',
                borderWidth: 1,
                order: 2
              }
            ]
          }
          setTimeout(()=> {
            var data = {
              labels: this.chartGuest.labels,
              datasets: this.LineChartDataGuest
            };
            if(this.chart6 !== null) this.chart6.destroy()
            var legendMargin= {
              id:'legendMargin',
              beforeInit(chart, args, options) {
                const fitValue = chart.legend.fit;
                chart.legend.fit= function fit(){
                  fitValue.bind(chart.legend)();
                  return this.height +=28
                }
              }
            }
            if(document.getElementById('LineChartDataGuest') !== null) {
              this.chart6 = new Chart(
                  document.getElementById('LineChartDataGuest'),
                  {
                    type: 'line',
                    data,
                    plugins: [legendMargin]
                  });
            }
          }, 1000)
        }
        if(true) {
          if (this.selectedDirection === null) {
            this.LineChartDataBankets = [
              {
                label: 'ПЛАН',
                data: this.chartBankets.plan,
                backgroundColor: '#ff9100',
                borderColor: '#ff9100',
                borderWidth: 1,
                order: 2
              },
              {
                label: 'ФАКТ',
                data: this.chartBankets.thisYear,
                backgroundColor: '#868686',
                borderColor: '#868686',
                borderWidth: 1,
                order: 2
              },
              {
                label: 'ПРОШЛЫЙ',
                data: this.chartBankets.oldYear,
                backgroundColor: '#284F9A',
                borderColor: '#284F9A',
                borderWidth: 1,
                order: 2
              }
            ]
          } else {
            this.LineChartDataBankets = [
              {
                label: 'ФАКТ',
                data: this.chartBankets.thisYear,
                backgroundColor: '#868686',
                borderColor: '#868686',
                borderWidth: 1,
                order: 2
              },
              {
                label: 'ПРОШЛЫЙ',
                data: this.chartBankets.oldYear,
                backgroundColor: '#284F9A',
                borderColor: '#284F9A',
                borderWidth: 1,
                order: 2
              }
            ]
          }

          setTimeout(()=> {
            var data = {
              labels: this.chartBankets.labels,
              datasets: this.LineChartDataBankets
            };
            if(this.chart7 !== null) this.chart7.destroy()
            var legendMargin= {
              id:'legendMargin',
              beforeInit(chart, args, options) {
                const fitValue = chart.legend.fit;
                chart.legend.fit= function fit(){
                  fitValue.bind(chart.legend)();
                  return this.height +=28
                }
              }
            }
            if(document.getElementById('LineChartDataBankets') !== null) {
              this.chart7 = new Chart(
                  document.getElementById('LineChartDataBankets'),
                  {
                    type: 'line',
                    data,
                    options: {
                      scales: {
                        xAxes: [{
                          gridLines: {
                            display: false
                          },
                          ticks: {
                            fontFamily: "Circe Bold",
                          }
                        }],
                      }
                    },
                    plugins: [legendMargin]
                  });
            }
          }, 1000)
        }
        this.load++
        this.canRequest = true
        setTimeout(()=>{
          // this.sizePie.width = document.getElementsByClassName('indicator-element')[0].clientWidth-55;
          // this.sizePie.height = document.getElementsByClassName('indicator-element')[0].offsetHeight-55;
          // this.ColumnChartOptions.width = document.getElementsByClassName('chart-line-container')[0].clientWidth;
          // this.ColumnChartOptions.height = document.getElementsByClassName('chart-line-container')[0].offsetHeight;
          // this.LineChartOptions.width = document.getElementsByClassName('chart-line-container')[0].clientWidth;
          // this.LineChartOptions.height = document.getElementsByClassName('chart-line-container')[0].offsetHeight;
          this.PieChartOptionsCustom.width=document.getElementsByClassName('chart-line-container')[0].clientWidth;
          this.PieChartOptionsCustom.height=document.getElementsByClassName('chart-line-container')[0].clientWidth;
          this.sizeLineChart.width=document.getElementsByClassName('chart-line-container')[0].clientWidth;
          this.sizeLineChart.height = document.getElementsByClassName('chart-line-container')[0].offsetHeight;
        },1000)
        this.error = null
      }).catch(() => {
        this.error = 'Возникла ошибка во время выполнения запроса. Как правило это означает что выполнение заняло чуть больше времени. Попробуйте нажать на кнопку "Применить фильтр" через 2 минуты'
        this.canRequest = true
      })
    }
  },
  watch: {
    'filter.direction': {
      handler: function (after, before) {
        this.loadData()
      },
      deep: true
    },
  },
  mounted() {
    if(window.innerWidth<2000){
      this.PieChartOptions.height=169
      this.PieChartOptionsCustom.height=169
    }
  }
}
</script>


<style scoped>
.chartMenu p {
  padding: 10px;
  font-size: 20px;
}
.chartCard {
  display: flex;
  align-items: center;
  justify-content: center;
}
.chartBox {
  width: 700px;
  background: white;
}
.colspan_1{
  display: grid;
  grid-template-columns: repeat(1,1fr);
  grid: calc(80vh - 370px) auto / 1fr;
  grid-column-gap: 30px;
  margin: 0 30px;
}
.colspan_1_5{
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1fr;
  grid-column-gap: 30px;
  margin: 0 30px;
}
.colspan_2{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-column-gap: 30px;
  margin: 0 30px;
}
.colspan_3{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-column-gap: 30px;
  margin: 0 30px;
}
.colspan_4{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-column-gap: 30px;
  margin: 0 30px;
}
.chart-line-container{
  height: calc(90vh - 450px);
}
button {
  cursor: pointer;
  position: relative;
  min-height: 50px;
  width: 350px;
  background: #998257;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #FFFFFF;
  padding-left: 25px;
  border: none;
}
.parameters-list{
  max-width: 400px;
  margin: 30px auto;
}
.parameters-element{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #222222;
  gap: 30px;
  padding: 10px;
}
.parameters-element__title{
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  text-transform: uppercase;
}
.parameters-element__value{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;

  color: #222222;
}
.dropdown-list{
  margin-top: 30px;
  margin-left: 30px;
  display: flex;
  gap:30px;
  flex-wrap: wrap;
}
.indicator-list{
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap:30px;
  flex-wrap:wrap;
  margin-bottom: 30px;
}
.indicator-element{
  width: 100%;
  height: 252px;
  background: rgba(153, 130, 87, 0.25);
  border-radius: 25px;
  padding: 25px;
  justify-self: center;
}
.main-info-check{
}
.main-info-check__title{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 31px;

  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #222222;
  text-align: left;
}
.main-info-check__cost{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 62px;

  text-transform: uppercase;

  color: #A0AB51;
  text-align: left;
}
.main-info-check__stats{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}
.main-info-check__stats span{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #A0AB51;
}
.deep-check{
  border-top: 2px solid black;
  padding-top: 13px;
  margin-top: 13px;
}
.deep-check__title{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 21px;
  line-height: 31px;
}
.deep-check__stats{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #222222;
}
.deep-check__stats span{
  color: #A0AB51;
}
.chart-info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.chart-info__title{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 31px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  color: #998257;
}
.chart-info__description{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #222222;
  text-align: center;
}
.chart-info__description span{
  color: #A0AB51;
}
.chart-line-container{
  margin-left: 30px;
}
button.disabled {
  max-width: unset;
  width: auto;
}
@media screen and (max-width: 2000px){
  .dropdown-list{
    margin-top: 10px;
    gap: 10px;
  }
  button{
    width: 250px;
  }
  .indicator-list{
    margin-top: 10px;
  }
  .indicator-element{
    width: 100%;
    height: 200px;
    padding: 15px;
  }
  .main-info-check__title{
    font-size: 16px;
    line-height: 25px;
  }
  .main-info-check__cost{
    font-size: 30px;
    line-height: 50px;
  }
  .main-info-check__stats{
    font-size: 13px;
    line-height: 20px;
  }
  .main-info-check__stats span{
    font-size: 13px;
    line-height: 20px;
  }
  .deep-check{
    border-top: 2px solid black;
    padding-top: 13px;
    margin-top: 13px;
  }
  .deep-check__title{
    font-size: 16px;
    line-height: 25px;
  }
  .deep-check__stats{
    font-size: 13px;
    line-height: 20px;
  }
  .chart-info__title{
    font-family: 'Circe Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 31px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    color: #998257;
  }
}
</style>