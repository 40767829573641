<template>
  <div class="container-task">
    <div>
      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">План на день {{date}} для ресторана {{select}}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <input type="text" v-model="plan"  style="width: 100%"/>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="savePlan()">Сохранить изменения</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h3 class="container__title" style="position: relative">ВЫРУЧКА <slot v-if="canRequest"><button class="filter" @click="loadData">Применить фильтр</button></slot><slot v-else><button class="filter disabled" disabled readonly="">Идет запрос, он может занимать до 5 минут. Подождите немного...</button></slot></h3>
    <div class="container__earnings" v-if="canRequest">
      <div class="container__earnings_left">
        <div class="calendar">
          <FunctionalCalendar
              v-model="calendarData"
              :configs="calendarConfigs"
          >
          </FunctionalCalendar>
        </div>
        <div class="graphs" v-if="chartInit">
          <div class="graphs-container-home">
            <bar-chart :key="load" :data="chart"/>
          </div>
        </div>
      </div>
      <div class="container__earnings_center" v-if="Object.keys(table).length && table[select]" :key="select+load">
        <div class="info-table">
          <div class="first-table-line">
            <div>Date</div>
            <div>Plan</div>
            <div>Actual</div>
          </div>
          <div class="second-table-line">
            <div>DD.MM.YY</div>
            <div>Revenue</div>
            <div>Revenue</div>
            <div>% Vs Plan</div>
            <div>% Yoy</div>
          </div>
          <div class="main-info-table">
            <div class="main-info-table__element" v-for="(row, index) in Object.values(table[select])" :class="(index % 2? 'dark-gray-row':'light-gray-row')">
              <div>{{ row.date }}</div>
              <div>{{ priceFormat(parseInt(row['plan'])) }}</div>
              <div>{{ priceFormat(parseInt(row['2022'])) }}</div>
              <div><percent :percentValue="parseInt(row['plan']? parseInt((row['2022']/row['plan']) * 100) : 0)" /></div>
              <div><percent :percentValue="parseInt(parseInt((row['2022']/row['2021']) * 100))" /></div>
            </div>
          </div>
          <div class="prevEnd-table-line">
            <div>Total</div>
            <div>{{ priceFormat(getTotal(select, 'plan')) }}</div>
            <div>{{ priceFormat(getTotal(select, '2022')) }}</div>
            <div><percent :percentValue="parseInt(getTotal(select, 'plan')? parseInt((getTotal(select, '2022')/getTotal(select, 'plan')) * 100) : 0) || 0" /></div>
            <div><percent :percentValue="parseInt((getTotal(select, '2022')/getTotal(select, '2021')) * 100) || 0" /></div>
          </div>
          <div class="end-table-line" v-if="showForecast">
            <div>Forecast</div>
            <div>{{ priceFormat(parseInt(forecast)) }}</div>
<!--            <div><percent :percentValue="parseInt(PoF)" /></div>-->
<!--            <div><percent :percentValue="parseInt(YoY)" /></div>-->
          </div>
        </div>
<!--        <table class="table-wrapper">
          <thead>
            <tr>
              <th>Date</th>
              <th>Plan</th>
              <th colspan="3">Actual</th>
            </tr>
            <tr class="title-row-table">
              <td>dd.mm.yy</td>
              <td>Revenue</td>
              <td>Revenue</td>
              <td>% vs plan</td>
              <td>%YoY</td>
            </tr>
          </thead>
          <tbody v-if="Object.keys(table).length && table[select]">
          <tr v-for="(row, index) in Object.values(table[select])" :class="(index % 2? 'dark-gray-row':'light-gray-row')">
            <td>{{ row.date }}</td>
            <td @click="setPlan(select, row.date, row.plan)"><slot v-if="!row.plan">установить</slot><slot v-else>{{ priceFormat(parseInt(row['plan'])) }}</slot></td>
            <td>{{ priceFormat(parseInt(row['2022'])) }}</td>
            <td><percent :percentValue="parseInt(row['plan']? parseInt((row['2022']/row['plan']) * 100) : 0)" /></td>
            <td><percent :percentValue="parseInt(parseInt((row['2022']/row['2021']) * 100))" /></td>
          </tr>
          </tbody>
          <tfoot>
          <tr class="bottom-row">
            <td colspan="2">Forecast</td>
            <td>{{ priceFormat(parseInt(forecast)) }}</td>
            <td><percent :percentValue="parseInt(PoF)" /></td>
            <td><percent :percentValue="parseInt(YoY)" /></td>
          </tr>
          </tfoot>
        </table>-->
      </div>
      <div class="container__earnings_right" v-if="Object.keys(totals).length">
        <div class="speedometer-container">
<!--          <div v-if="Object.keys(table).length">-->
          <div style="display: flex; justify-content: center">
            <div @click="select = 'all'" style="cursor: pointer">
              <div class="title-speedometer">Total</div>
              <speedometer :variant="'total'" :val="parseInt(totals.all.sum)" :max="parseInt((totals.all.plan===0?totals.all.sum:totals.all.plan))" :size="100"/>
            </div>
          </div>
          <div style="display: flex; gap:10px;justify-content: center">
            <div @click="select = 'RIBAMBELLE Vremena Goda'" style="cursor: pointer">
              <div class="title-speedometer" :key="'RIBAMBELLE Vremena Goda'">ВГ | Лок. A</div>
              <speedometer :variant="'1'" :val="parseInt(totals['RIBAMBELLE Vremena Goda'].sum)" :max="parseInt((totals['RIBAMBELLE Vremena Goda'].plan===0?totals['RIBAMBELLE Vremena Goda'].sum:totals['RIBAMBELLE Vremena Goda'].plan))" :size="100"/>
            </div>

            <div @click="select = 'RIBAMBELLE Green'" style="cursor: pointer">
              <div class="title-speedometer" :key="'RIBAMBELLE Green'">БС | Лок. B</div>
              <speedometer :variant="'2'" :val="parseInt(totals['RIBAMBELLE Green'].sum)" :max="parseInt((totals['RIBAMBELLE Green'].plan===0?totals['RIBAMBELLE Green'].sum:totals['RIBAMBELLE Green'].plan))":size="100"/>
            </div>
          </div>
          <div style="display: flex; gap:10px; justify-content: center">
            <div @click="select = 'Ribambelle Obolensky'" style="cursor: pointer">
              <div class="title-speedometer" :key="'Ribambelle Obolensky'">ОБ | Лок. C</div>
              <speedometer :variant="'3'" :val="parseInt(totals['Ribambelle Obolensky'].sum)" :max="parseInt((totals['Ribambelle Obolensky'].plan===0?totals['Ribambelle Obolensky'].sum:totals['Ribambelle Obolensky'].plan))" :size="100"/>
            </div>
            <div @click="select = 'Ribambelle Сердце Столицы'" style="cursor: pointer">
              <div class="title-speedometer" :key="'Ribambelle Сердце Столицы'">CC | Лок. D</div>
              <speedometer :variant="'4'" :val="parseInt(totals['Ribambelle Сердце Столицы'].sum)" :max="parseInt((totals['Ribambelle Сердце Столицы'].plan===0?totals['Ribambelle Сердце Столицы'].sum:totals['Ribambelle Сердце Столицы'].plan))" :size="100"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min'
import { FunctionalCalendar } from 'vue-functional-calendar';
import BarChart from "@/Components/BarChart";
import Speedometer from "@/Components/speedometer";
import Percent from "@/Components/Percent";
export default {
  components:{Percent, Speedometer, BarChart, FunctionalCalendar},
  name: 'HomeView',
  data () {
    return {
      select: 'all',
      calendarData: {},
      calendarKeyCheck: 0,
      forecast: 0,
      countDays: 0,
      showForecast: true,
      YoY: 0,
      PoF: 0,
      plan: 0,
      rest: null,
      date: null,
      timer: null,
      chartInit: false,
      canRequest: true,
      range: {
        start: null,
        end: null
      },
      load: 0,
      totals: {},
      chart: {
        labels: [],
        oldYear: [],
        thisYear: [],
        plan: []
      },
      calendarConfigs: {
        sundayStart: false,
        dateFormat: 'yyyy-mm-dd',
        isDatePicker: false,
        isDateRange: true,
        minSelDays: 2,
        maxSelDays: 31,
        dayNames: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
        monthNames: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]
      },
      table: []
    }
  },
  methods: {
    getTotal (array, value) {
      var returnVal = 0
      if(this.table[array]) {
        Object.values(this.table[array]).forEach(val => {
          returnVal += val[value]
        })
      }
      return returnVal
    },
    priceFormat(value) {
      if(!value) return '0 ₽'
      var formatter = new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
        minimumFractionDigits: 0
      })
      return formatter.format((value || 0)) || '0 ₽'
    },
    loadData() {
      this.canRequest = false
      axios.get('https://graph-api.ribambelle.ru/site/reports?start='+this.range.start+'&end='+this.range.end).then(res=> {
        this.countDays = res.data.countDays
        this.showForecast = res.data.showForecast
        this.table = res.data.table
        this.totals = res.data.totals
        this.calendarKeyCheck++
        /*var index = 0
        var sum = 0
        var plan = 0
        var thisYear = 0
        var oldYear = 0
        this.chartInit=false
        this.chart={
          labels: [],
          oldYear: [],
          thisYear: [],
          plan: []
        }
        Object.values(this.table.all).forEach(val => {
          this.chart.labels.push(val.date)
          this.chart.thisYear.push(val[2022])
          this.chart.oldYear.push(val[2021])
          this.chart.plan.push(val.plan)
          sum += val[2022]
          index++
          plan+= val.plan
          thisYear += val[2022]
          oldYear += val[2021]
        })
        this.chartInit=true
        if(this.showForecast) {
          this.forecast = (sum / index) * countDays
          this.YoY = thisYear / oldYear * 100
          this.PoF = thisYear / plan * 100
        }*/
        this.load++
        this.reRenderSelect(this.select)
        this.canRequest = true
        this.$forceUpdate()
      })
    },
    reRenderSelect(val) {
      var index = 0
      var sum = 0
      var plan = 0
      var thisYear = 0
      var oldYear = 0
      this.chartInit=false
      this.chart={
        labels: [],
        oldYear: [],
        thisYear: [],
        plan: []
      }
      Object.values(this.table[val]).forEach(val => {
        this.chart.labels.push(val.date)
        this.chart.thisYear.push(val[2022])
        this.chart.oldYear.push(val[2021])
        this.chart.plan.push(val.plan)
        sum += val[2022]
        index++
        plan+= val.plan
        thisYear += val[2022]
        oldYear += val[2021]
      })
      this.chartInit=true
      this.forecast = (sum/index)*this.countDays
      this.YoY = thisYear/oldYear*100
      this.PoF = thisYear/plan*100
      this.load++
    }
  },
  mounted() {
    // this.calendarConfigs.limits = {min: '1/1/2018',max: "18/10/2022"}
    // this.calendarKeyCheck++
    // this.loadData()
  },
  watch: {
    calendarData: {
      handler: function (after, before) {
        this.range=this.calendarData.dateRange
      },
      deep: true
    },
    range: {
      handler: function (after, before) {
      },
      deep: true
    },
    select (val) {
      this.reRenderSelect(val)
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  }
}
</script>

<style scoped>
button.filter {
  cursor: pointer !important;
  position: absolute;
  background: #998257 !important;
  border-radius: 25px !important;
  display: inline-block;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 38px !important;
  letter-spacing: 0.1em !important;
  text-transform: uppercase !important;
  color: #FFFFFF !important;
  padding: 0 15px;
  border: none !important;
  margin-left: 30px;
  left: 200px;
  bottom: 4px;
}
</style>

<style>
.vfc-top-date span{
  color: #998257 !important;
  text-transform: uppercase;
}
.vfc-week .vfc-day span.vfc-span-day.vfc-today{
  background: rgba(229,223,213) !important;
}
.vfc-week .vfc-day .vfc-base-end{
  background: rgba(229,223,213) !important;
}
.vfc-week .vfc-day .vfc-base-start{
  background-color: rgba(229,223,213)!important;
}
.vfc-week .vfc-day:last-child .vfc-base-start{
  background-color: transparent!important;
}
/*.vfc-week .vfc-day span.vfc-span-day{
  width: 50px!important;
  line-height: 50px!important;
}*/
.vfc-content{
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;

  color: #000000;
}
.vfc-week .vfc-day span.vfc-span-day.vfc-marked{
  background-color: rgba(229,223,213) !important;
  border-radius: 50%;
}
.vfc-week .vfc-day span.vfc-span-day.vfc-marked:before{
  background-color: rgba(229,223,213) ;
}
.vfc-week .vfc-day:last-child span.vfc-span-day.vfc-marked:before{

  width: 50%;
}
.vfc-week .vfc-day:first-child .vfc-base-end {
  background-color: transparent !important;
}
.vfc-week .vfc-day:first-child span.vfc-span-day.vfc-marked:before{

  width: 50%;
  right: 0;
  left: inherit;
}

.container-task{
  /*min-height: calc(100vh - 42px);*/
  max-height: calc(100vh - 42px);
}
.container__title{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  margin: 0;
  text-align: left;
  padding-left: 30px;
  padding-top: 20px;
}
.container__earnings{
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;

  column-gap: 20px;
  max-height: calc(100vh - 43px);

}
.container__earnings_left{
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.calendar{
  width: 100%;
  max-height: calc(50% - 10px);
}
.graphs{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  max-height: calc(50% - 10px);
  width: 100%;
}
.container__earnings_center{
}
.info-table{

}
.first-table-line{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;

  color: #222222;
  display: grid;
  grid-template-columns: 1fr 1fr 3fr;
  background: rgba(153, 130, 87, 0.25);
  margin-bottom: 2px;
  height: 40px;
  text-transform: uppercase;
}
.first-table-line div{
  display: flex;
  align-items: center;
  justify-content: center;
}
.second-table-line{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;

  color: #222222;
  display: grid;
  height: 40px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  background: rgba(153, 130, 87, 0.25);
  text-transform: uppercase;
}
.second-table-line div{
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-info-table{
  /*margin-bottom: 40px;*/
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  /*max-height: 75vh;*/
  max-height: calc(100vh - 42px - 53.6px - 80px - 40px + -86px);
  overflow: auto;
  color: #222222;
}
.main-info-table__element{
  display: grid;
  height: 40px;
  grid-template-columns: repeat(5, 1fr);
  border-bottom: 1px solid #222222;
}
.main-info-table__element div{
  display: flex;
  align-items: center;
  justify-content: center;
}
.prevEnd-table-line{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;

  color: #222222;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  background: rgba(153, 130, 87, 0.25);
  margin-bottom: 2px;
  height: 40px;
  text-transform: uppercase;
}
.prevEnd-table-line div{
  display: flex;
  align-items: center;
  justify-content: center;
}
.end-table-line{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;

  color: #222222;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  background: rgba(153, 130, 87, 0.25);
  margin-bottom: 2px;
  height: 40px;
  text-transform: uppercase;
}
.end-table-line div{
  display: flex;
  align-items: center;
  justify-content: center;
}
.profit{
  color: #A0AB51 !important;
}
.neutral{
  color: #545454 !important;
}
.profit-circle{
  display: inline-block;
}
.profit-arrow{

}
.loss{
  color: #D95841 !important;
}
.loss-circle{
  display: inline-block;
}
.loss-arrow{

}
.title-speedometer{
  font-family: 'Circe';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 31px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #998257;
  margin-top: 15px;
  text-align: center;
}

.speedometer-container{
  display:flex;
  flex-direction: column;
  gap:5px;
}
@media screen and (max-width: 2000px){
  .graphs-container-home{
    width: 400px;
    height: 400px;
  }
}
</style>
