<template>
  <div>
    <div class="dropdown-list">
      <drop-down-calendar
          @select-deal="optionSelectDate"
      />
      <button @click="loadData">Применить фильтр</button>
    </div>
      <div class="parameters-list">
        <div class="parameter-element" v-if="table.checks > 0">
          <div class="main-info-check">
            <div class="main-info-check__title">Средний чек</div>
            <div class="main-info-check__cost">{{ priceFormat(table.paySum/leads.release) }}</div>
            <div class="main-info-check__stats"><span :style="(table.paySum - table.oldAvg) > 0 ? {}:{color: '#AB5151'}">{{ (table.paySum - table.oldAvg) > 0 ? '+ '+priceFormat((table.paySum/leads.release) - table.oldAvg):priceFormat((table.paySum/leads.release) - table.oldAvg) }}</span> к прошлому году</div>
          </div>
        </div>
        <div class="parameter-element" v-if="leads.income > 0">
          <div class="main-info-check">
            <div class="main-info-check__title">Конверсия</div>
            <div class="main-info-check__cost">{{ numberFormat((leads.release/leads.income) * 100) }}%</div>
<!--            <div class="main-info-check__stats"><span>+0,24 %</span> к прош.году</div>-->
          </div>
          <div class="сonversion-footer">
            <div class="сonversion-footer__left">
              <div>Входящих</div>
              <div class="сonversion-footer__left_counter">{{ numberFormat(leads.income) }}</div>
            </div>
            <div class="сonversion-footer__right">
              <div>Реализованных</div>
              <div class="сonversion-footer__left_counter">{{ numberFormat(leads.release) }}</div>
            </div>
          </div>
        </div>
        <div class="expenses-wrapper" v-if="table.costs.length">
          <div class="expenses-title">Затраты</div>
          <div class="expenses-list">
            <div class="expenses-el" v-for="cost in table.costs">
              <div>{{ cost.name }}</div>
              <div>{{ numberFormat((cost.value/table.paySum)*100) }}<slot v-if="numberFormat((cost.value/table.paySum)*100)">%</slot></div>
              <div>{{ priceFormat(cost.value) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="parameters-list-footer" :style="Object.keys(leads).length?(((!leads[selectedDirection][0].sum&&leads[selectedDirection][0].count)||(leads[selectedDirection][0].sum&&!leads[selectedDirection][0].count))?{gridTemplateColumns:'1fr 1fr 2fr'}:{}):{}">
        <div class="chart-line-container">

          <bar-chart v-if="chartInit" :key="load" :data="chart"/>
<!--          <canvas id="ColumnChartDataAvgCheck"
          :width="sizeLineChart.width"
          :height="sizeLineChart.height"
          ></canvas>-->
        </div>
        <div>
          <slot v-if="Object.keys(leads).length">
          <div class="title-doughnutChart-wrapper">
            <div class="title-doughnutChart">Доли по каналам</div>
            <div class="selects-doughnutChart">
              <div style="display: none">
                <select>
                  <option>Все заявки</option>
                  <option>Заявка 1</option>
                  <option>Заявка 2</option>
                </select>
              </div>
              <div>
                <select v-model="selectedDirection">
                  <option v-for="option in optionsDirections" :value="option.value">{{option.name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="parameters-list-footer__central" :key="selectedDirection">
            <div :style="((!leads[selectedDirection][0].sum&&leads[selectedDirection][0].count)||(leads[selectedDirection][0].sum&&!leads[selectedDirection][0].count))?{width:'100%',position:'relative'}:{position:'relative'}" v-if="leads[selectedDirection][0].count">
              <div
                  style="width: 100%; height: 40px; position: absolute; top: 50%; left: 0; margin-top: -20px; line-height:19px; text-align: center; z-index: 999999999999999">
                Количество<br/>
                {{ numberFormat(leads[selectedDirection][0].count) }}
              </div>
              <canvas id="DoughnutChartDataAll"></canvas>
            </div>
            <div :style="((!leads[selectedDirection][0].sum&&leads[selectedDirection][0].count)||(leads[selectedDirection][0].sum&&!leads[selectedDirection][0].count))?{width:'100%',position:'relative'}:{position:'relative'}" v-if="leads[selectedDirection][0].sum">
              <div
                  style="width: 100%; height: 40px; position: absolute; top: 50%; left: 0; margin-top: -20px; line-height:19px; text-align: center; z-index: 999999999999999">
                Сумма<br/>
                {{ priceFormat(leads[selectedDirection][0].sum) }}
              </div>
              <canvas id="DoughnutChartDataAllNext"
              ></canvas>
            </div>
          </div>
          </slot>
        </div>
        <slot v-if="Object.keys(leads).length">
        <div class="table-parameters" v-if="leads[selectedDirection]">
          <div class="title-table-parameter">
            <div>наименование</div>
            <div>кол-во</div>
            <div>%&nbsp;кол-во</div>
            <div v-if="selectedDirection !== 'report'">сумма</div>
            <div v-if="selectedDirection !== 'report'">%&nbsp;сумма</div>
          </div>
          <div class="main-parameters-table">
            <div :class="index%2?'main-parameters-table__element light-gray-row':'main-parameters-table__element dark-gray-row'" v-for="(channel, index) in leads[selectedDirection]" :key="channel.name" v-if="channel.name">
              <div><div :style="{backgroundColor: (selectedDirection !== 'report'?colors[index-1]:reportColors[index-1]), width: '12px', height: '12px', borderRadius: '10px', display: 'inline-block', margin: 'auto auto',marginRight: '3px',}"></div>
                {{ channel.name }}</div>
              <div>{{ numberFormat(channel.count)||0 }}</div>
              <div>{{ (numberFormat((channel.count / leads[selectedDirection][0].count) * 100))||0 }}%</div>
              <div v-if="selectedDirection !== 'report'">{{ priceFormat(channel.sum)||0 }}</div>
              <div v-if="selectedDirection !== 'report'">{{ numberFormat((channel.sum / leads[selectedDirection][0].sum) * 100)||0 }}%</div>
            </div>

            <div class="main-parameters-table__element dark-gray-row">
              <div>ИТОГО</div>
              <div>{{ numberFormat(leads[selectedDirection][0].count)||0 }}</div>
              <div></div>
              <div v-if="selectedDirection !== 'report'">{{ priceFormat(leads[selectedDirection][0].sum)||0 }}</div>
              <div v-if="selectedDirection !== 'report'"></div>
            </div>
          </div>
        </div>
        <div class="table-parameters" v-else>
          <div class="title-table-parameter">
            <div>канал</div>
            <div>кол-во</div>
            <div>%&nbsp;кол-во</div>
            <div>сумма</div>
            <div>%&nbsp;сумма</div>
          </div>
          <div class="main-parameters-table">
          </div>
        </div>
        </slot>
      </div>
  </div>
</template>

<script>
import DropDown from "@/Components/dropDown";
import DropDownCalendar from "@/Components/dropDownCalendar";
import GoogleChart from "@/Components/GoogleChart";
import Chart from "chart.js/auto";
import axios from "axios";
import BarChart from "@/Components/BarChart";

export default {
  name: "PresentationTwo",
  components: { GoogleChart, DropDown, BarChart, DropDownCalendar},
  data(){
    return{
      sizeLineChart:{
        height:0,
        width:0,
      },
      colors: [
        'rgb(255,0,51)',
        'rgb(255,143,0)',
        'rgb(255,248,0)',
        'rgb(0,255,19)',
        'rgb(0,255,255)',
        'rgb(0,61,255)',
        'rgb(153,0,255)',
        'rgb(164,160,160)',
        'rgb(103,58,0)',
        'rgb(255,0,253)'
      ],
      reportColors: [
        'rgb(0,255,19)',
        'rgb(255,0,51)'
      ],
      filter: {
        date: null
      },
      chartInit: false,
      load: 0,
      chart: {
        labels: [],
        oldYear: [],
        thisYear: [],
        plan: []
      },
      ColumnChartDataAvgCheck:[
        ['Дата', 'ТЕКУЩИЙ ГОД', 'ПРОШЛЫЙ ГОД'], //, 'ПЛАН'
      ],
      DoughnutChartDataAll:[],
      chart1: null,
      chart2:null,
      chart3:null,

      chartAll: {
        labels: [],
        oldYear: [],
        thisYear: [],
        plan: [],
        init: false
      },
      chartCircle: {},
      selectedDirection:'channels',
      optionsDirections: [
        {name:'Каналы', value:'channels'},
        {name:'Сегменты', value:'type'},
        {name:'Поводы B2B', value:'b2b'},
        {name:'Поводы B2C', value:'b2c'},
        {name:'Активность', value:'new'},
        {name:'Отзывы', value:'report'},
      ],
      table: {
        paySum: 0,
        oldSum: 0,
        checks: 0,
        costs: [],
        plan: 0
      },
      leads: {
      }
    }
  },
  watch: {
    selectedDirection () {
      this.initGraph()
    }
  },
  methods:{
    priceFormat(value) {
      if(!value) return ''
      var formatter = new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
        minimumFractionDigits: 0
      })
      return formatter.format((value || 0)) || 0
    },
    numberFormat(value) {
      if(!value) return ''
      var formatter = new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: 0
      })
      return formatter.format((value || 0)) || 0
    },
    loadData() {
      var url = []
      if(this.filter.restaurant) url.push('restaurant='+this.filter.restaurant)
      if(this.filter.date&&this.filter.date.start) url.push('start='+this.filter.date.start)
      if(this.filter.date&&this.filter.date.end) url.push('end='+this.filter.date.end)
      if(this.filter.direction) url.push('direction='+this.filter.direction)
      this.chartInit=false
      axios.get('https://graph-api.ribambelle.ru/site/page2-bs?'+url.join('&')).then(res=> {
        this.table = res.data
        if(this.table.graphs.avgCheck) {

          var index = 0
          var sum = 0
          var plan = 0
          var thisYear = 0
          var oldYear = 0
          this.chart={
            labels: [],
            oldYear: [],
            thisYear: [],
            plan: []
          }
          Object.values(this.table.graphs.avgCheck).forEach(val => {
            this.chart.labels.push(val.date)
            this.chart.thisYear.push(val.this)
            this.chart.oldYear.push(val.last)
            // this.chart.plan.push(val.plan)
            sum += val.this
            index++
            // plan+= val.plan
            thisYear += val.this
            oldYear += val.last
          })
          this.chartInit=true
          this.load++
          /*

          var labels = []
          this.ColumnChartDataAvgCheck = [
            {
              label: 'ТЕКУЩИЙ ГОД',
              data: [],
              backgroundColor: 'rgba(54, 162, 235, 1)',
              // borderColor: 'rgba(54, 162, 235, 1)',
              // borderWidth: 1,
              // order: 2
            },
            {
              label: 'ПРОШЛЫЙ ГОД',
              data: [],
              backgroundColor: 'rgba(255, 26, 104, 1)',
              // borderColor: 'rgba(255, 26, 104, 1)',
              // borderWidth: 1,
              // order: 1
            },
            {
              label: 'ПЛАН',
              data: [],
              backgroundColor: 'rgb(255,152,26)',
              // borderColor: 'rgba(255, 26, 104, 1)',
              // borderWidth: 1,
              // order: 1
            }
          ]
          Object.values(this.table.graphs.avgCheck).forEach(val => {
            labels.push(val.date)
            this.ColumnChartDataAvgCheck[0].data.push(Math.round(val.this))
            this.ColumnChartDataAvgCheck[1].data.push(Math.round(val.last))
            this.ColumnChartDataAvgCheck[2].data.push(Math.round(val.plan))
          })
          setTimeout(()=> {
            var data = {
              labels: labels,
              datasets: this.ColumnChartDataAvgCheck
            };
            if(this.chart1 !== null) this.chart1.destroy()
            var legendMargin= {
              id:'legendMargin',
              beforeInit(chart, args, options) {
                const fitValue = chart.legend.fit;
                chart.legend.fit= function fit(){
                  fitValue.bind(chart.legend)();
                  return this.height +=28
                }
              }
            }
            if(document.getElementById('ColumnChartDataAvgCheck') !== null) {
              this.chart1 = new Chart(
                  document.getElementById('ColumnChartDataAvgCheck'),
                  {
                    type: 'line',
                    data,
                    options: {
                      scales: {
                        x: {
                          stacked: false
                        },
                        y: {
                          beginAtZero: true
                        }
                      }
                    },
                    plugins: [legendMargin]
                  });
            }
          }, 300)*/
        }
      })
      axios.get('https://api.letsbot.ru/events/get-leads?'+url.join('&')).then(res => {
        this.leads = res.data
        this.initGraph()
      })
    },
    optionSelectDate(option){
      this.filter.date=option;
    },
    initGraph() {
      Chart.defaults.font.size=12
      Chart.defaults.font.family='Circe Bold'
      Chart.defaults.color = "#222222";
      if(this.leads[this.selectedDirection][0].count) {
        if (this.chart2 !== null) this.chart2.destroy()
        setTimeout(() => {

          var labels = [];
          var array = []
          var colors = []

          this.leads[this.selectedDirection].forEach(value => {
            if (value.name) {
              labels.push(value.name)
              array.push(value.count)
            }
          })
          if(this.selectedDirection !== 'report') colors = this.colors
          else colors = this.reportColors
          var data = {
            labels: labels,
            datasets: [{
              label: 'My First Dataset',
              data: array,
              backgroundColor: colors,
              hoverOffset: 4
            }]
          };
          if (document.getElementById('DoughnutChartDataAll') !== null) {
            this.chart2 = new Chart(
                document.getElementById('DoughnutChartDataAll'),
                {
                  type: 'doughnut',
                  data,
                  options: {
                    plugins: {legend: {display: false},},
                  }
                });
          }
        }, 300)
      }
      if(this.leads[this.selectedDirection][0].sum) {
        if (this.chart3 !== null) this.chart3.destroy()
        setTimeout(() => {

          var labels = [];
          var array = []

          this.leads[this.selectedDirection].forEach(value => {
            if (value.name) {
              labels.push(value.name)
              array.push(value.sum)
            }
          })

          var data = {
            labels: labels,
            datasets: [{
              label: 'My First Dataset',
              data: array,
              backgroundColor: [
                'rgb(255,0,51)',
                'rgb(255,143,0)',
                'rgb(255,248,0)',
                'rgb(0,255,19)',
                'rgb(0,255,255)',
                'rgb(0,61,255)',
                'rgb(153,0,255)',
                'rgb(164,160,160)',
                'rgb(103,58,0)',
                'rgb(255,0,253)'
              ],
              hoverOffset: 4
            }]
          };
          if (document.getElementById('DoughnutChartDataAllNext') !== null) {
            this.chart3 = new Chart(
                document.getElementById('DoughnutChartDataAllNext'),
                {
                  type: 'doughnut',
                  data,
                  options: {plugins: {legend: {display: false},}}
                });
          }
        }, 300)
      }
    }
  },
  mounted() {
  }
}
</script>


<style scoped>
.dropdown-list{
  margin-top: 30px;
  margin-left: 30px;
  display: flex;
  gap:30px;
  flex-wrap: wrap;
}
.dropdown-list button {
  cursor: pointer;
  position: relative;
  min-height: 50px;
  width: 350px;
  background: #998257;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #FFFFFF;
  padding-left: 25px;
  border: none;
}
.parameters-list{
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  gap:30px;
  flex-wrap:wrap;
  margin-bottom: 30px;
}
.parameter-element{
  min-width: 400px;
  height: 252px;
  background: rgba(153, 130, 87, 0.25);
  border-radius: 25px;
  padding: 25px;
  justify-self: center;
}
.main-info-check{
}
.main-info-check__title{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 31px;

  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #222222;
  text-align: left;
}
.main-info-check__cost{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 62px;

  text-transform: uppercase;

  color: #A0AB51;
  text-align: left;
}
.main-info-check__stats{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}
.main-info-check__stats span{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #A0AB51;
}
.сonversion-footer{
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  grid-column-gap: 10px;
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  border-top: 2px solid black;
  padding-top: 13px;
  margin-top: 13px;
  color: white;
}
.сonversion-footer__left{
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 5px;
  background-color: #998257;
}
.сonversion-footer__right{
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 5px;
  background-color: #998257;
}
.сonversion-footer__left_counter{
  font-size: 24px;
}
.expenses-wrapper{
  min-width: 400px;

  background: rgba(153, 130, 87, 0.25);
  border-radius: 25px;
  padding: 10px 25px;
  justify-self: center;
}
.expenses-title{
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
}
.expenses-list{
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
}
.expenses-el{
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  border-radius: 10px;
  padding: 10px 15px;
  background-color: #998257;
}
.parameters-list-footer{
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 1fr 2fr 2fr;
  grid-template-rows:calc(100vh - 600px);
}
.title-doughnutChart-wrapper{
  margin-bottom: 30px;
}
.title-doughnutChart{
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
}
.selects-doughnutChart{
 display: flex;
}
.selects-doughnutChart div select{
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  border: 0;
  outline: none;
}
.parameters-list-footer__central{
  display: flex;
  justify-content: center;
  gap: 10px;
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.parameters-list-footer__central div{
  width: 50%;
}
.colspan_1{
  display: grid;
  grid-template-columns: repeat(1,1fr);
  grid: calc(80vh - 370px) auto / 1fr;
  grid-column-gap: 30px;
  margin: 0 30px;
}
.colspan_1_5{
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1fr;
  grid-column-gap: 30px;
  margin: 0 30px;
}
.colspan_2{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-column-gap: 30px;
  margin: 0 30px;
}
.colspan_3{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-column-gap: 30px;
  margin: 0 30px;
}
.colspan_4{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-column-gap: 30px;
  margin: 0 30px;
}
.table-parameters{

}
.title-table-parameter{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: #222222;
  background: rgba(153, 130, 87, 0.25);
  padding: 10px;
  grid-column-gap: 5px;
  white-space: nowrap;
}
.title-table-parameter div{
  display: flex;
  align-items: center;
}
.main-parameters-table{

}
.main-parameters-table__element{
  padding: 10px;
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 5px;
}
.light-gray-row{

}
.dark-gray-row{
  background: rgba(153, 130, 87, 0.15);
}
@media screen and (max-width: 2000px){
  .dropdown-list{
    margin-top: 10px;
  }
  .parameters-list{
    margin-top: 10px;
    gap:20px;
  }
  .parameter-element{
    height: 200px;
    padding: 15px;
  }
  .main-info-check__title{
    font-size: 16px;
    line-height: 25px;
  }
  .main-info-check__cost{
    font-size: 32px;
    line-height: 52px;
  }
  .main-info-check__stats{
    font-size: 13px;
    line-height: 20px;
  }
  .main-info-check__stats span{
    font-size: 13px;
    line-height: 20px;
  }
  .сonversion-footer{
    font-size: 13px;
    line-height: 17px;
  }

  .сonversion-footer__left_counter{
    font-size: 20px;
  }
  .expenses-wrapper{
    padding: 10px 25px;
  }
  .expenses-title{
    font-size: 13px;
    line-height: 17px;
  }
  .expenses-list{
   font-size: 10px;
  }
  .expenses-el{
    padding: 8px 15px;
  }
}
</style>