<template>
  <div id="app">
    <ul class="nav nav-tabs">
<!--      <li class="nav-item">
        <a :class="'nav-link ' + (select === 'first' ? 'active':'')" @click="changeTab('first')">Первая</a>
      </li>
      <li class="nav-item">
        <a :class="'nav-link ' + (select === 'second' ? 'active':'')" @click="changeTab('second')">Вторая</a>
      </li>
      <li class="nav-item">
        <a :class="'nav-link ' + (select === 'plan' ? 'active':'')" @click="changeTab('plan')">Планирование</a>
      </li>-->
      <li class="nav-item">
        <a :class="'nav-link ' + (select === 'bs-1' ? 'active':'')" @click="changeTab('bs-1')">БС</a>
      </li>
      <li class="nav-item">
        <a :class="'nav-link ' + (select === 'bs-2' ? 'active':'')" @click="changeTab('bs-2')">БС 2</a>
      </li>
      <li class="nav-item">
        <a :class="'nav-link ' + (select === 'bs-plan' ? 'active':'')" @click="changeTab('bs-plan')">БС Планирование</a>
      </li>
    </ul>
    <home-view v-show="select === 'first'"/>
    <second-home-view v-show="select === 'second'"/>
    <plan-view v-show="select === 'plan'"/>
    <presentation-one v-show="select === 'bs-1'"/>
    <presentation-two v-show="select === 'bs-2'"/>
    <plan-two-view v-show="select === 'bs-plan'"/>
  </div>
</template>

<script>

import homeView from "@/HomeView";
import SecondHomeView from "@/SecondHomeView";
import PlanView from "@/PlanView";
import PlanTwoView from "@/PlanTwoView";
import PresentationOne from "@/PresentationOne";
import PresentationTwo from "@/PresentationTwo";

export default {
  name: "App",
  components:{PresentationTwo, PresentationOne, SecondHomeView, homeView, PlanView, PlanTwoView},
  data(){
    return{
      // select: 'first'
      select: 'bs-1'
    }
  },
  methods:{
    changeTab(select){
      this.select=select
    }
  }
}
</script>

<style>
body,html{
  padding: 0;
  margin: 0;
}
.nav-item{
  cursor: pointer;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
