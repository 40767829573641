<template>
  <div class="container-task">

    <div class="container__earnings">
      <div><h3 class="container__titleNew"><button class="filter" @click="loadData">Применить фильтр</button></h3></div>
      <div></div>
      <div></div>
      <div class="container__earnings_left">
        <div class="calendar">
          <FunctionalCalendar
              v-model="calendarData"
              :configs="calendarConfigs"
          >
          </FunctionalCalendar>
        </div>
        <div class="graphs" v-if="chartInit">
          <div class="graphs-container">
            <bar-chart :key="load" :data="chart"/>
          </div>
        </div>
      </div>
      <div class="container__earnings_center" v-if="Object.keys(table).length && table[select]">
        <div class="info-table">
          <div class="first-table-line">
            <div>Date</div>
            <div>Plan</div>
            <div>Actual</div>
          </div>
          <div class="second-table-line">
            <div>DD.MM.YY</div>
            <div>Revenue</div>
            <div>Revenue</div>
            <div>% Vs Plan</div>
            <div>% Yoy</div>
          </div>
          <div class="main-info-table">
            <div class="main-info-table__element" v-for="(row, index) in Object.values(table[select])" :class="(index % 2? 'dark-gray-row':'light-gray-row')">
              <div>{{ row.date }}</div>
              <div><!-- -->
                <slot v-if="!row.plan"></slot><slot v-else>{{ priceFormat(parseInt(row['plan'])) }}</slot>
              </div>
              <div>{{ priceFormat(parseInt(row['2022'])) }}</div>
              <div><percent :percentValue="parseInt(row['plan']? parseInt((row['2022']/row['plan']) * 100) : 0)" /></div>
              <div><percent2 :percentValue="row['2021']>0&&row['2022']==0?0:parseInt(parseInt((row['2022']/row['2021']) * 100)-100)" /></div>
            </div>
          </div>
          <div class="prevEnd-table-line">
            <div>Total</div>
            <div>{{ priceFormat(getTotal(select, 'plan')) }}</div>
            <div>{{ priceFormat(getTotal(select, '2022')) }}</div>
            <div><percent :percentValue="parseInt(getTotal(select, 'plan')? parseInt((getTotal(select, '2022')/getTotal(select, 'plan')) * 100) : 0)" /></div>
            <div><percent2 :percentValue="parseInt(parseInt((getTotal(select, '2022')/getTotal(select, '2021')) * 100)-100)" /></div>
          </div>
          <div class="end-table-line" v-if="showForecast">
            <div>Forecast</div>
            <div>{{ priceFormat(parseInt(forecast)) }}</div>
<!--            <div><percent :percentValue="parseInt(PoF)" /></div>-->
<!--            <div><percent :percentValue="parseInt(YoY)" /></div>-->
          </div>
        </div>
      </div>
      <div class="container__earnings_right" v-if="Object.keys(totals).length">
        <div class="speedometer-container">
          <div style="display: flex; justify-content: center">
            <div @click="select = 'all'" style="cursor: pointer">
              <div class="title-speedometer">Total</div>
              <speedometer :variant="'total'"
                           :key="load" :val="parseInt(totals.all.sum)" :max="parseInt((plan))" :size="100"/>
            </div>
          </div>
          <div>
            <div class="chart-info">
              <div class="chart-info__title">Доля выручки</div>
              <google-chart
                  :key="load"
                  :type="PieChartType"
                  :data="PieChartDataAll"
                  :options="PieChartOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min'
import { FunctionalCalendar } from 'vue-functional-calendar';
import BarChart from "@/Components/BarChart";
import Speedometer from "@/Components/speedometer";
import Percent from "@/Components/Percent";
import Percent2 from "@/Components/Percent2";
import GoogleChart from "@/Components/GoogleChart";
export default {
  components:{GoogleChart, Percent, Percent2, Speedometer, BarChart, FunctionalCalendar},
  name: 'PresentationOne',
  data () {
    return {
      plan: 0,
      PieChartType:'PieChart',
      PieChartDataAll:[
        ['Канал', 'Значение'],
      ],
      PieChartOptions:{
        title: '',
        width: 356,
        height: 356,
        chartArea:{top:0,width:'100%',height:'100%'},
        slices: {
          0: { color: '#AFCBBF' },
          1: { color: '#C5B2C3' },
          2: { color: '#EAE8D8' },
        },
        pieSliceTextStyle:{
          fontSize:12,
          color: '#222222',
          fontName:'Circe Bold',
        },
        legend: 'none',
      },
      select: 'all',
      calendarData: {},
      calendarKeyCheck: 0,
      forecast: 0,
      YoY: 0,
      PoF: 0,
      plan: 0,
      rest: null,
      date: null,
      timer: null,
      chartInit: false,
      range: {
        start: null,
        end: null
      },
      load: 0,
      totals: {},
      chart: {
        labels: [],
        oldYear: [],
        thisYear: [],
        plan: []
      },
      showForecast: true,
      calendarConfigs: {
        sundayStart: false,
        dateFormat: 'yyyy-mm-dd',
        isDatePicker: false,
        isDateRange: true,
        minSelDays: 2,
        maxSelDays: 31,
        dayNames: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
        monthNames: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]
      },
      table: []
    }
  },
  methods: {
    getTotal (array, value) {
      var returnVal = 0
      if(this.table[array]) {
        Object.values(this.table[array]).forEach(val => {
          returnVal += val[value]
        })
      }
      return returnVal
    },
    priceFormat(value) {
      if(!value) return '0 ₽'
      var formatter = new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
        minimumFractionDigits: 0
      })
      return formatter.format((value || 0)) || '0 ₽'
    },
    loadData() {
      axios.get('https://graph-api.ribambelle.ru/site/reports-bs?start='+this.range.start+'&end='+this.range.end).then(res=> {
        var countDays = res.data.countDays
        this.showForecast = res.data.showForecast
        this.plan = res.data.plan
        this.table = res.data.table
        this.totals = res.data.totals
        this.calendarKeyCheck++
        var index = 0
        var sum = 0
        var plan = 0
        var thisYear = 0
        var oldYear = 0
        this.chartInit=false
        this.chart={
          labels: [],
          oldYear: [],
          thisYear: [],
          plan: []
        }
        Object.values(this.table.all).forEach(val => {
          this.chart.labels.push(val.date)
          this.chart.thisYear.push(val[2022])
          this.chart.oldYear.push(val[2021])
          this.chart.plan.push(val.plan)
          sum += val[2022]
          index++
          plan+= val.plan
          thisYear += val[2022]
          oldYear += val[2021]
        })
        this.chartInit=true
        if(this.showForecast) {
          this.forecast = (sum / index) * countDays
          this.YoY = thisYear / oldYear * 100
          this.PoF = thisYear / plan * 100
        }

        this.PieChartDataAll = [
          ['Канал', 'Значение'],
        ]
        Object.keys(res.data.stats).forEach(key => {
          var val = res.data.stats[key]
          this.PieChartDataAll.push([key, val])
        })
        this.load++
      })
    }
  },
  mounted() {
    if(window.innerWidth<2000){
      this.PieChartOptions.width=250
      this.PieChartOptions.height=250
    }
    // this.calendarConfigs.limits = {min: '1/1/2018',max: "18/10/2022"}
    // this.calendarKeyCheck++
    // this.loadData()
  },
  watch: {
    calendarData: {
      handler: function (after, before) {
        this.range=this.calendarData.dateRange
      },
      deep: true
    },
    range: {
      handler: function (after, before) {
      },
      deep: true
    },
    select (val) {
      var index = 0
      var sum = 0
      var plan = 0
      var thisYear = 0
      var oldYear = 0
      this.chartInit=false
      this.chart={
        labels: [],
        oldYear: [],
        thisYear: [],
        plan: []
      }
      Object.values(this.table[val]).forEach(val => {
        this.chart.labels.push(val.date)
        this.chart.thisYear.push(val[2022])
        this.chart.oldYear.push(val[2021])
        this.chart.plan.push(val.plan)
        sum += val[2022]
        index++
        plan+= val.plan
        thisYear += val[2022]
        oldYear += val[2021]
      })
      this.chartInit=true
      this.forecast = (sum/index)*(index+1)
      this.YoY = thisYear/oldYear*100
      this.PoF = thisYear/plan*100
      this.load++
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  }
}
</script>

<style scoped>
button.filter {
  cursor: pointer;
  background: #998257;
  border-radius: 25px;
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  line-height: 38px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 0 15px;
  border: none;
}
</style>

<style>
.chart-info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.chart-info__title{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 31px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  color: #998257;
}
.vfc-calendar .vfc-content{
  margin-bottom: 10px!important;
}
.vfc-top-date span{
  color: #998257 !important;
  text-transform: uppercase;
}
.vfc-week .vfc-day span.vfc-span-day.vfc-today{
  background: rgba(229,223,213) !important;
}
.vfc-week .vfc-day .vfc-base-end{
  background: rgba(229,223,213) !important;
}
.vfc-week .vfc-day .vfc-base-start{
  background-color: rgba(229,223,213)!important;
}
.vfc-week .vfc-day:last-child .vfc-base-start{
  background-color: transparent!important;
}
/*.vfc-week .vfc-day span.vfc-span-day{
  width: 50px!important;
  line-height: 50px!important;
}*/
.vfc-content{
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;

  color: #000000;
}
.vfc-week .vfc-day span.vfc-span-day.vfc-marked{
  background-color: rgba(229,223,213) !important;
  border-radius: 50%;
}
.vfc-week .vfc-day span.vfc-span-day.vfc-marked:before{
  background-color: rgba(229,223,213) ;
}
.vfc-week .vfc-day:last-child span.vfc-span-day.vfc-marked:before{

  width: 50%;
}
.vfc-week .vfc-day:first-child .vfc-base-end {
  background-color: transparent !important;
}
.vfc-week .vfc-day:first-child span.vfc-span-day.vfc-marked:before{

  width: 50%;
  right: 0;
  left: inherit;
}

.container-task{
  max-height: calc(100vh - 42px);
}
.container__titleNew{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  text-align: center;
  padding-top: 20px;
  margin-bottom: 20px;
}
.container__earnings{
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;

  column-gap: 20px;
  /*height: calc(100vh - 42px - 53.6px);*/

}
.container__earnings_left{
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.calendar{
  width: 100%;
  max-height: calc(50% - 10px);
}
.graphs{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  max-height: calc(50% - 10px);
  width: 100%;
}
.graphs-container{

}
.container__earnings_center{
}
.info-table{

}
.first-table-line{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;

  color: #222222;
  display: grid;
  grid-template-columns: 1fr 1fr 3fr;
  background: rgba(153, 130, 87, 0.25);
  margin-bottom: 2px;
  height: 40px;
  text-transform: uppercase;
}
.first-table-line div{
  display: flex;
  align-items: center;
  justify-content: center;
}
.second-table-line{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;

  color: #222222;
  display: grid;
  height: 40px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  background: rgba(153, 130, 87, 0.25);
  text-transform: uppercase;
}
.second-table-line div{
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-info-table{
  /*margin-bottom: 40px;*/
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  /*max-height: 75vh;*/
  max-height: calc(100vh - 42px - 53.6px - 80px - 40px + -86px);
  overflow: auto;
  color: #222222;
}
.main-info-table__element{
  display: grid;
  height: 40px;
  grid-template-columns: repeat(5, 1fr);
  border-bottom: 1px solid #222222;
}
.main-info-table__element div{
  display: flex;
  align-items: center;
  justify-content: center;
}
.prevEnd-table-line{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;

  color: #222222;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  background: rgba(153, 130, 87, 0.25);
  margin-bottom: 2px;
  height: 40px;
  text-transform: uppercase;
}
.prevEnd-table-line div{
  display: flex;
  align-items: center;
  justify-content: center;
}
.end-table-line{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;

  color: #222222;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  background: rgba(153, 130, 87, 0.25);
  margin-bottom: 2px;
  height: 40px;
  text-transform: uppercase;
}
.end-table-line div{
  display: flex;
  align-items: center;
  justify-content: center;
}
.profit{
  color: #A0AB51 !important;
}
.profit-circle{
  display: inline-block;
}
.profit-arrow{

}
.loss{
  color: #D95841 !important;
}
.loss-circle{
  display: inline-block;
}
.loss-arrow{

}
.title-speedometer{
  font-family: 'Circe';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 31px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #998257;
  margin-top: 15px;
  text-align: center;
}
.speedometer-container{
  display:flex;
  flex-direction: column;
  gap:5px;
}
@media screen and (max-width: 2000px){
  .graphs-container{
    width: 400px;
    height: 400px;
  }
}
</style>
