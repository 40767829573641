import Vue from 'vue'
import App from './App.vue'
import '../public/styles/style.css'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min'
import 'bootstrap/dist/css/bootstrap.css'

Vue.use(bootstrap)
new Vue({
  render: h => h(App)
}).$mount('#app')
