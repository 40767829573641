<template>
  <LineChartGenerator :key="chartData.datasets.length"
      :chartData="chartData"
      :chartOptions="chartOptions"
      :chartId="chartId"
      :width="width"
      :height="height"
      :cssClasses="cssClasses"
      :styles="styles"
      :plugins="plugins"
  />
</template>
<script>
  import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
  import { Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Filler

  } from 'chart.js'
  ChartJS.register(
      Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement, Filler)

  export default {
    name: 'BarChart',
    components: { LineChartGenerator },
    props: {
      chartId: {
        type: String,
        default: 'line-chart'
      },
      width: {
        type: Number,
        default: 300
      },
      height: {
        type: Number,
        default: 300
      },
      cssClasses: {
        default: '',
        type: String
      },
      styles: {
        type: Object,
        default: () => {}
      },
      data: {
        type: Object,
        default: () => {}
      },
      plugins: {
        type: Array,
        default: () => []
      }
    },

    data() {
      return {
        chartData: {
          labels: [],
          datasets: []
        },
        chartOptions: {
          scales: {
            y: {
              ticks: { color: 'black'}
            },
            x: {
              ticks: { color: 'black'}
            },
          },
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              display: true,
              labels: {
                font:{
                  size:12,
                  family:'Circe Bold',
                  weight:'bold',
                }
              }
            }
          },
        }
      }
    },
    mounted() {
      this.chartData.labels = this.data.labels
      if((this.data.plan).length > 0) {
        this.chartData.datasets.push({
          label: 'План',
          borderColor: '#ff9100',
          pointBackgroundColor: '#ff9100',
          fill: false,

          backgroundColor: (ctx) => {
            const canvas = ctx.chart.ctx;
            const gradient = canvas.createLinearGradient(0, 0, 0, 400);
            gradient.addColorStop(0, '#ff9100');
            gradient.addColorStop(1, 'white');
            return gradient;
          },
          tension: 0.25,
          data: this.data.plan
        })
      }
      this.chartData.datasets.push({
        label: 'Факт',
        borderColor: '#868686',
        pointBackgroundColor: '#868686',
        fill: false,

        backgroundColor: (ctx) => {
          const canvas = ctx.chart.ctx;
          const gradient = canvas.createLinearGradient(0,0,0,400);
          gradient.addColorStop(0, '#868686');
          gradient.addColorStop(1, 'white');
          return gradient;
        },
        tension: 0.25,
        data: this.data.thisYear
      })
      this.chartData.datasets.push({
        label: 'Прошлый',
        borderColor: '#284F9A',
        pointBackgroundColor: '#284F9A',
        fill: false,

        backgroundColor: (ctx) => {
          const canvas = ctx.chart.ctx;
          const gradient = canvas.createLinearGradient(0,0,0,400);
          gradient.addColorStop(0, '#284F9A');
          gradient.addColorStop(1, 'white');
          return gradient;
        },
        tension: 0.25,
        data: this.data.oldYear
      })
    }
  }
</script>

<style scoped>

</style>