<template>
  <div class="wrapperId" :id="'wrapper'+variant">
    <img class="img-back" src="/my/Group.png" alt="">
    <img class="meter_needleId" :id="'meter_needle'+variant" src="/my/Union.svg" alt="">
    <div class="min-max-speedometer" :style="gapStyles">
      <div>0</div>
      <div>{{ priceFormat(max) }}</div>
    </div>
    <div class="speedometer-actual">Actual</div>
    <div :id="'lbl'+variant" class="lblId" >{{ priceFormat(val) }}</div>
  </div>
</template>

<script>
export default {
  name: "speedometer",
  props: ['variant','size', 'val', 'max'],
  data(){
    return{
      gapStyles:''
    }
  },
  methods: {
    priceFormat(value) {
      if(!value) return ''
      var formatter = new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
        minimumFractionDigits: 0
      })
      return formatter.format((value || 0)) || 0
    }
  },
  mounted() {
    var deg=225
    if(this.val>=this.max)deg=135
    if(this.val<=0)deg=225
    if(this.val>0&&this.val<this.max)deg=-(135-(Math.ceil(this.val*270/this.max)))
    var meter_needle =  document.querySelector('#meter_needle'+this.variant);
    meter_needle.style.transform = 'rotate(' + (deg) + 'deg)';
  }
}
</script>

<style scoped>
.wrapperId {position: relative;  margin: auto;}
.wrapperId .img-back{
  width: 100%;
  height: 100%;
}
.min-max-speedometer{
  position: absolute;
  bottom: 20%;
  left: 28%;
  display: flex;
  color: #222222;
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  max-width: 44%;
  width: 100%;
  justify-content: space-between;
}
.lblId {
  position: absolute;
  border-radius: 2px;
  font-family: 'Circe';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 31px;
  text-transform: uppercase;

  color: #998257;
  padding: 4px 4px 2px 4px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 3%;
}
.meter_needleId {
  height: 45%;
  position: absolute;
  z-index: 1;
  left: 46%;
  top: 5%;
  transform-origin: center bottom;
}
.speedometer-actual{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 31px;
  /* identical to box height */
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #222222;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
}
@media (-webkit-min-device-pixel-ratio: 0.75) {
  #wrappertotal .img-back{
    width: 350px;
  }
  #wrapper1 .img-back{
    width: 250px;
  }
  #wrapper2 .img-back{
    width: 250px;
  }
  #wrapper3 .img-back{
    width: 250px;
  }
  #wrapper4 .img-back{
    width: 250px;
  }
  #wrapper1 .min-max-speedometer{
    font-size: 9px;
  }
  #wrapper2 .min-max-speedometer{
    font-size: 9px;
  }
  #wrapper3 .min-max-speedometer{
    font-size: 9px;
  }
  #wrapper4 .min-max-speedometer{
    font-size: 9px;
  }
  #wrapper1 .lblId{
    font-size: 12px;
  }
  #wrapper2 .lblId{
    font-size: 12px;
  }
  #wrapper3 .lblId{
    font-size: 12px;
  }
  #wrapper4 .lblId{
    font-size: 12px;
  }
  #wrapper1 .speedometer-actual{
    font-size: 12px;
  }
  #wrapper2 .speedometer-actual{
    font-size: 12px;
  }
  #wrapper3 .speedometer-actual{
    font-size: 12px;
  }
  #wrapper4 .speedometer-actual{
    font-size: 12px;
  }
}
@media screen and (max-width: 2000px){
  #wrappertotal .img-back{
    width: 250px;
  }
  #wrapper1 .img-back{
    width: 215px;
  }
  #wrapper2 .img-back{
    width: 215px;
  }
  #wrapper3 .img-back{
    width: 215px;
  }
  #wrapper4 .img-back{
    width: 215px;
  }
  #wrapper1 .min-max-speedometer{
     font-size: 9px;
   }
  #wrapper2 .min-max-speedometer{
    font-size: 9px;
  }
  #wrapper3 .min-max-speedometer{
    font-size: 9px;
  }
  #wrapper4 .min-max-speedometer{
    font-size: 9px;
  }
  #wrapper1 .lblId{
    font-size: 12px;
  }
  #wrapper2 .lblId{
    font-size: 12px;
  }
  #wrapper3 .lblId{
    font-size: 12px;
  }
  #wrapper4 .lblId{
    font-size: 12px;
  }
  #wrapper1 .speedometer-actual{
    font-size: 12px;
  }
  #wrapper2 .speedometer-actual{
    font-size: 12px;
  }
  #wrapper3 .speedometer-actual{
    font-size: 12px;
  }
  #wrapper4 .speedometer-actual{
    font-size: 12px;
  }
}
</style>