<template>
  <GChart :type="type" :data="data" :options="options" />
</template>

<script>
import { GChart } from 'vue-google-charts/legacy';

export default {
  name: 'GoogleChart',
  components: {
    GChart,
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Array,
      default: () => []
    }
  },
};
</script>
