<template>
  <span>
    <span v-if="percent>0" class="profit">{{percent}}% <span class="profit-circle">●</span> <span class="profit-arrow">↑</span></span>
    <span v-else-if="percent==0" class="neutral">{{percent}}% <span class="profit-circle">●</span></span>
    <span v-else-if="percent<0" class="loss">{{percent}}% <span class="loss-circle">●</span> <span class="loss-arrow">↓</span></span>
    <span v-else-if="percent==100" class="profit">{{percent}}% <span class="profit-circle">●</span></span>
  </span>
</template>

<script>
export default {
  name: "Percent",
  props:{
      percentValue: {
        type: Number,
        default: 0
      }
  },
  computed: {
    percent () {
      var val = 0
      if(isNaN(this.percentValue)) val = 0
      else val = this.percentValue
      return val
    }
  }
}
</script>

<style scoped>

</style>