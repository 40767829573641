<template>
  <div class="v-select">
    <div class="select-main" @click="areOptionsVisible=!areOptionsVisible">
      <div
          class="title"
      >
        {{getSelected||title}}
      </div>
      <div class="icon-select">
        <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.5711 1.00001L8.5 8.07108L1.42893 1.00001" stroke="white" stroke-width="2"/>
        </svg>
      </div>
    </div>
    <div class="options" v-if="areOptionsVisible" v-on-clickaway="closeSelect">
        <div
            class="options__element"
            v-for="option in options"
            :key="option.value"
            @click="selectOption(option)"
        >
          {{option.name}}
        </div>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
export default {
  name: "dropDown",
  directives: {
    onClickaway: onClickaway,
  },
  props:{
    options:{
      type:Array,
      default(){
        return []
      }
    },
    selected:{
      type:String,
      default:''
    },
    title:{
      type:String,
      default:''
    }
  },
  data(){
    return {
      areOptionsVisible:false,
    }
  },
  computed: {
    getSelected () {
      let obj = this.options.find(o => o.value === this.selected)
      if (obj) return obj.name
      else return this.selected
    }
  },
  methods:{
    selectOption(option){
      this.$emit('select-deal',option)
      this.areOptionsVisible=false;
    },
    closeSelect(){
      this.areOptionsVisible=false;
    }
  }

}
</script>

<style scoped>
.v-select{
  cursor: pointer;
  position: relative;
  min-height: 50px;
  width: 350px;
  background: #998257;
  border-radius: 25px;
  display: flex;
  justify-content: center;
}
.select-main{
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}
.title{
  font-family: 'Circe Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #FFFFFF;
  padding-left: 25px;
}

.v-select p{
  margin: 0;
}
.icon-select{
  padding-right: 18px;
}
.options{
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #222222;
  background: rgb(229,223,213);
  border-radius: 25px;
  position: absolute;
  z-index: 100;
  top:60px;
  right: 0;
  width: 100%;
  padding: 10px 25px;
}

.options__element{
  padding:10px 0;
  cursor: pointer
}

@media screen and (max-width: 2000px) {

  .v-select{
    width: 250px;
  }

}
@media screen and (max-width: 500px) {

  .v-select{
    width: auto;
    height: auto;
  }
  .icon-select{
    margin: 0;
  }

}
@media screen and (max-width: 400px) {
  .options .overflow div{
    width: 90%;

  }
}

</style>